<template>
  <div>
    <router-link :to="'/' + $i18n.locale + '/konto-erstellen'">
      <div class="font-bold p-2 m-2 rounded shadow border hover:bg-slate-100">
        {{ $t("createAccount") }}
      </div>
    </router-link>
    <router-link :to="'/' + $i18n.locale + '/ertragsrechner'">
      <div class="font-bold p-2 m-2 rounded shadow border hover:bg-slate-100">
        {{ $t("calculator") }}
      </div>
    </router-link>
  </div>
</template>
