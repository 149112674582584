import { toRaw } from "vue";

export default {
  getStartingState() {
    return {
      jwt: "",
      persons: [{}, {}],
      fieldsets: {},
      bankInfo: {},
      responses: [],
      applications: [],
      lastApplicationGuid: "unspecified",
      applicationsFilter: {
        filter: "1",
        search: "",
        mode: "more",
        sortMode: "desc",
        sortType: "date",
      },
    };
  },
  removeObjectWithId(arr, guid) {
    const objWithIdIndex = this.getObjectViaId(arr, guid);

    if (objWithIdIndex > -1) {
      arr.splice(objWithIdIndex, 1);
    }
    return arr;
  },
  // todo make update object via id function
  getObjectViaId(arr, guid) {
    arr.forEach((obj) => {
      if (obj.guid == guid) {
        /*  console.log(toRaw(obj)); */
        return toRaw(obj);
      }
    });
  },
  getStepsArray(locale = "de") {
    if (locale == "en") {
      return [
        "1 - Investmentperiod & Amount",
        "2 - Personal Information",
        "3 - Account Information",
        "4 - Questionnaire",
        "5 - Form Completion",
        "6 - Started CRIF",
        "7 - CRIF Identification completed",
        "8 - CRIF Signature completed",
      ];
    }

    return [
      "1 - Anlagedauer & Betrag",
      "2 - Persönliche Informationen",
      "3 - Kontoinformationen",
      "4 - Fragebogen",
      "5 - Formular Abschluss",
      "6 - CRIF begonnen",
      "7 - CRIF Identifikation abgeschlossen",
      "8 - CRIF Signatur abgeschlossen",
    ];
  },
  beautifyDate(date, dateOnly = false, timeOnly = false) {
    if (!(date instanceof Date)) {
      /*  console.warn("Wrong INPUT:", date); */
      return undefined;
    }

    if (dateOnly) {
      return (
        date.getDate() + "." + (date.getMonth() + 1) + "." + date.getFullYear()
      );
    }

    if (timeOnly) {
      let minutes = date.getMinutes();
      if (minutes == 0) {
        minutes = "00";
      }
      return date.getHours() + ":" + minutes;
    }

    return (
      date.getDate() +
      "." +
      (date.getMonth() + 1) +
      "." +
      date.getFullYear() +
      " " +
      date.toLocaleTimeString().substring(0, 5)
    );
  },
  monthsToString(months, i18n) {
    months = Number(months);

    if (months < 12) {
      return months + " " + i18n.t("months2");
    } else {
      if (months % 12 == 0) {
        if (months / 12 == 1) {
          return "1 " + i18n.t("year");
        }
        return Math.floor(months / 12) + " " + i18n.t("years");
      }
      if (months / 12 == 1) {
        if (months % 12 == 1) {
          return (
            Math.floor(months / 12) +
            " " +
            i18n.t("year") +
            " " +
            (months % 12) +
            " " +
            i18n.t("month")
          );
        }
        return (
          Math.floor(months / 12) +
          " " +
          i18n.t("year") +
          " " +
          (months % 12) +
          " " +
          i18n.t("months2")
        );
      }
      if (months % 12 == 1) {
        return (
          Math.floor(months / 12) +
          " " +
          i18n.t("years") +
          " " +
          (months % 12) +
          " " +
          i18n.t("month")
        );
      }
      return (
        Math.floor(months / 12) +
        " " +
        i18n.t("years") +
        " " +
        (months % 12) +
        " " +
        i18n.t("months2")
      );
    }
  },
  sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  },
};
