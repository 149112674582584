<template>
  <div>
    <label class="block text-sm font-bold mb-2" for="range">
      {{ $t("invPeriod") }}: {{ anlageDauerText }}
    </label>
    <input
      type="range"
      :min="0"
      :max="options.length - 1"
      class="range range-primary"
      step="1"
      id="range"
      required
      :aria-valuetext="anlageDauerText"
      v-model="anlageDauerValue"
    />
    <div class="w-full flex justify-between text-xs px-2" aria-hidden="true">
      <span v-for="option in options" :key="option">|</span>
    </div>
  </div>
</template>

<script>
import shared from "@/shared";

export default {
  data() {
    return {
      anlageDauerValue: 0,
    };
  },
  props: {
    options: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    anlageDauerValue() {
      this.$emit("update:modelValue", this.anlageDauerMonate);
    },
  },
  async created() {
    let anlagedauerMonate;
    await this.$router.isReady();

    if (this.$route.query.anlagedauer) {
      anlagedauerMonate = Number(this.$route.query.anlagedauer);
    } else {
      anlagedauerMonate = this.options[0];
    }
    this.anlageDauerValue = this.monthToVal(anlagedauerMonate);
  },
  methods: {
    valToMonth(val) {
      return this.options[val];
    },
    monthToVal(month) {
      return this.options.indexOf(month);
    },
  },
  computed: {
    anlageDauerText() {
      return shared.monthsToString(this.anlageDauerMonate, this.$i18n);
    },
    anlageDauerMonate() {
      return this.valToMonth(this.anlageDauerValue);
    },
  },
};
</script>
