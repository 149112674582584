<template>
  <form @submit="sendStepFour" @submit.prevent="onSubmit">
    <div>
      <h2
        v-if="previewPerson1"
        class="font-bold bg-primary text-center text-white rounded p-1"
      >
        Angaben für {{ previewPerson1.firstName }}
        {{ previewPerson1.lastName }}:
      </h2>
      <FieldsetsStepFour
        @update="updateFieldsets"
        :showErrorMessage="showErrorMessage"
      />

      <div
        v-if="$route.query.country == 'at'"
        style="
          background-color: rgba(128, 128, 128, 0.274);
          padding: 10px;
          margin-bottom: 20px;
          border-radius: 5px;
        "
      >
        Wir bitten um Verständnis, dass wir gegebenenfalls wegen der
        Geldwäschebestimmungen einen Nachweis zur Mittelherkunft bzw. zu den
        monatlichen Netto-Einkünften von Ihnen einholen müssen.
      </div>
    </div>

    <div v-if="previewPerson2">
      <h2
        id="two"
        class="font-bold bg-primary text-center text-white rounded p-1"
      >
        Angaben für {{ previewPerson2.firstName }}
        {{ previewPerson2.lastName }}:
      </h2>
      <FieldsetsStepFour
        @update="updateFieldsets2"
        :showErrorMessage="showErrorMessage"
        id-addon="1"
      />
      <div
        v-if="$route.query.country == 'at'"
        style="
          background-color: rgba(128, 128, 128, 0.274);
          padding: 10px;
          margin-bottom: 20px;
          border-radius: 5px;
        "
      >
        Wir bitten um Verständnis, dass wir gegebenenfalls wegen der
        Geldwäschebestimmungen einen Nachweis zur Mittelherkunft bzw. zu den
        monatlichen Netto-Einkünften von Ihnen einholen müssen.
      </div>
    </div>
    <!-- <div>
      <p>Preview Person 2: {{ previewPerson2 }}</p>
    </div> -->
    <p v-if="showErrorMessage">{{ errorMessage }}</p>
    <div class="flex justify-between py-3">
      <button type="button" class="btn btn-grey" @click="$emit('goBack')">
        {{ $t("back") }}
      </button>
      <button v-if="loading" class="btn btn-primary">
        {{ $t("continue") }} <LoadingCircle />
      </button>
      <button v-else class="btn btn-primary">{{ $t("continue") }}</button>
    </div>
  </form>
</template>

<script>
import axios from "axios";
import FieldsetsStepFour from "./FieldsetsStepFour.vue";
import LoadingCircle from "@/components/LoadingCircle.vue";
import { toRaw } from "vue";

export default {
  components: { LoadingCircle, FieldsetsStepFour },
  props: {
    guid: {
      type: String,
    },
  },
  data() {
    return {
      // general
      loading: false,
      showErrorMessage: false,
      errorMessage: "",

      fieldsets: {
        // fieldset 0
        dienstort_inland: false,
        wohnsitzaenderung: false,
        immobilienerwerb: false,
        vermittlung: false,
        finanzplatz_austria: false,
        langfristige_veranlagung_inland: false,
        sonstige_1: false,
        sonstige_1_text: "",

        // fieldset 1
        einkommen_unselbststaendig: false,
        einkommen_freiberuf: false,
        dividende_etc: false,
        virtuellen_etc: false,
        erbschaft_schenkung: false,
        verkauf_vermoegenswerte: false,
        ablauf_von_vertraegen: false,
        sonstige_2: false,
        sonstige_2_text: "",

        // fieldset 2
        lohn_gehaltszettel: false,
        einkommenssteuererklaerung: false,
        depotauszug: false,
        erbschaft: false,
        sonstige_vertraege: false,
        sonstige_3: false,
        sonstige_3_text: "",
        selectedOption: "",
        selectedOption1: "",

        // fieldset
        weitere_veranlagungen_geplant: false,
        /* contact */
        contactCall: false,
        contactEmail: false,
        contactCallAndEmail: false,
        contactdisConsent: false,
        //fieldset 4
        akt_einkommen_unselbststaendig: false,
        akt_einkommen_unselbststaendig_amount: "",
        akt_einkommen_unselbststaendig_employer: "",
        akt_einkommen_freiberuf: false,
        akt_dividende_etc: false,
        kein_einkommen: false,
        akt_sonstige: false,
        sonstige_5_text: "",
      },
      fieldsets_2: {
        // fieldset 0
        dienstort_inland: false,
        wohnsitzaenderung: false,
        immobilienerwerb: false,
        vermittlung: false,
        finanzplatz_austria: false,
        langfristige_veranlagung_inland: false,
        sonstige_1: false,
        sonstige_1_text: "",

        // fieldset 1
        einkommen_unselbststaendig: false,
        einkommen_freiberuf: false,
        dividende_etc: false,
        virtuellen_etc: false,
        erbschaft_schenkung: false,
        verkauf_vermoegenswerte: false,
        ablauf_von_vertraegen: false,
        sonstige_2: false,
        sonstige_2_text: "",
        selectedOption: "",
        selectedOption1: "",
        // fieldset 2
        lohn_gehaltszettel: false,
        einkommenssteuererklaerung: false,
        depotauszug: false,
        erbschaft: false,
        sonstige_vertraege: false,
        sonstige_3: false,
        sonstige_3_text: "",

        // fieldset
        weitere_veranlagungen_geplant: false,
        contactCall: false,
        contactEmail: false,
        contactCallAndEmail: false,
        /*         contactConsent: false,
         */ contactdisConsent: false,

        //fieldset 4
        akt_einkommen_unselbststaendig: false,
        akt_einkommen_freiberuf: false,
        akt_dividende_etc: false,
        kein_einkommen: false,
        akt_sonstige: false,
        sonstige_5_text: "",
        akt_einkommen_unselbststaendig_amount: "",
        akt_einkommen_unselbststaendig_employer: "",
      },
    };
  },
  computed: {
    previewPerson1() {
      return toRaw(this.$store.state.persons[0]);
    },
    previewPerson2() {
      let p2 = toRaw(this.$store.state.persons[1]);
      if (p2.firstName !== undefined && p2.firstName !== null) {
        return p2;
      }
      
      return null;
    },
    fieldsetsOkay() {
      let returnObject = [false, false, false, false];

      if (
        this.fieldsets.dienstort_inland ||
        this.fieldsets.wohnsitzaenderung ||
        this.fieldsets.immobilienerwerb ||
        this.fieldsets.vermittlung ||
        this.fieldsets.finanzplatz_austria ||
        this.fieldsets.langfristige_veranlagung_inland ||
        this.fieldsets.sonstige_1
      ) {
        returnObject[0] = true;
      }
      // Check the selected option for fieldset 1
      if (
        this.fieldsets.selectedOption === "einkommen_unselbststaendig" ||
        this.fieldsets.selectedOption === "einkommen_freiberuf" ||
        this.fieldsets.selectedOption === "dividende_etc" ||
        this.fieldsets.selectedOption === "virtuellen_etc" ||
        this.fieldsets.selectedOption === "erbschaft_schenkung" ||
        this.fieldsets.selectedOption === "verkauf_vermoegenswerte" ||
        this.fieldsets.selectedOption === "ablauf_von_vertraegen" ||
        this.fieldsets.selectedOption === "sonstige_2"
      ) {
        returnObject[1] = true;
      }

      /**
       * NOTICE: IF NEEDED TO GET "Nachweis der Einkünfte / Vermögen - Beibringen von Dokumenten (Mehrfachauswahl)" back,
       * look at the github commits before 23.03.23 🙂
       */

      if (
        this.fieldsets.contactCall || // Include the contact options here
        this.fieldsets.contactEmail ||
        this.fieldsets.contactCallAndEmail ||
        this.fieldsets.contactdisConsent
      ) {
        returnObject[2] = true;
      }
      if (
        this.fieldsets.selectedOption1 === "akt_einkommen_unselbststaendig" ||
        this.fieldsets.selectedOption1 === "akt_einkommen_freiberuf" ||
        this.fieldsets.selectedOption1 === "akt_dividende_etc" ||
        this.fieldsets.selectedOption1 === "kein_einkommen" ||
        this.fieldsets.selectedOption1 === "akt_sonstige"
      ) {
        returnObject[3] = true;
      }

      return returnObject;
    },
    fieldsets2Okay() {
      let returnObject = [false, false, false, false];

      if (
        this.fieldsets_2.dienstort_inland ||
        this.fieldsets_2.wohnsitzaenderung ||
        this.fieldsets_2.immobilienerwerb ||
        this.fieldsets_2.vermittlung ||
        this.fieldsets_2.finanzplatz_austria ||
        this.fieldsets_2.langfristige_veranlagung_inland ||
        this.fieldsets_2.sonstige_1
      ) {
        returnObject[0] = true;
      }
      if (
        this.fieldsets_2.selectedOption === "einkommen_unselbststaendig" ||
        this.fieldsets_2.selectedOption === "einkommen_freiberuf" ||
        this.fieldsets_2.selectedOption === "dividende_etc" ||
        this.fieldsets_2.selectedOption === "virtuellen_etc" ||
        this.fieldsets_2.selectedOption === "erbschaft_schenkung" ||
        this.fieldsets_2.selectedOption === "verkauf_vermoegenswerte" ||
        this.fieldsets_2.selectedOption === "ablauf_von_vertraegen" ||
        this.fieldsets_2.selectedOption === "sonstige_2"
      ) {
        returnObject[1] = true;
      }

      /**
       * NOTICE: IF NEEDED TO GET "Nachweis der Einkünfte / Vermögen - Beibringen von Dokumenten (Mehrfachauswahl)" back,
       * look at the github commits before 23.03.23 🙂
       */

      if (
        this.fieldsets_2.contactCall || // Include the contact options here
        this.fieldsets_2.contactEmail ||
        this.fieldsets_2.contactCallAndEmail ||
        this.fieldsets_2.contactdisConsent
      ) {
        returnObject[2] = true;
      }
      if (
        this.fieldsets_2.selectedOption1 === "akt_einkommen_unselbststaendig" ||
        this.fieldsets_2.selectedOption1 === "akt_einkommen_freiberuf" ||
        this.fieldsets_2.selectedOption1 === "akt_dividende_etc" ||
        this.fieldsets_2.selectedOption1 === "kein_einkommen" ||
        this.fieldsets_2.selectedOption1 === "akt_sonstige"
      ) {
        returnObject[3] = true;
      }

      return returnObject;
    },
  },
  methods: {
    updateFieldsets(fieldsets) {
      console.log("Update Fieldsets...");
      this.fieldsets = fieldsets;
    },
    updateFieldsets2(fieldsets) {
      console.log("Update Fieldsets Optional (2)...");
      this.fieldsets_2 = fieldsets;
    },
    sendStepFour() {
      // also check data...
      this.$store.state.fieldsets = this.fieldsets;
      this.$store.state.fieldsets_2 = this.fieldsets_2;

      // german requirements: [0,2]
      // austrian requirements: [1,3]
      console.log("Fieldsets:", this.fieldsets);
      console.log("FieldsetsOkay:", this.fieldsetsOkay);
      console.log("Fieldsets 2:", this.fieldsets_2);
      console.log("FieldsetsOkay 2:", this.fieldsetsOkay);

      let abort = false;

      if (this.$route.query.country == "at") {
        if (!this.fieldsetsOkay[1] || !this.fieldsetsOkay[3]) {
          console.log("Issue because of Austrian Laws");
          abort = true;
        }
      } else if (this.$route.query.country == "de") {
        if (
          !this.fieldsetsOkay[0] ||
          !this.fieldsetsOkay[2] ||
          !this.fieldsetsOkay[1]
        ) {
          console.log("Issue because of German Laws");
          abort = true;
        }
      }

      if (abort) {
        console.log("Not okay.");
        this.showErrorMessage = true;
        this.errorMessage =
          "Bitte wählen Sie bei jedem Fieldset zumindest eine der Optionen aus.";
        window.scrollTo(0, 0);
        return;
      }

      if (this.previewPerson2) {
        if (this.$route.query.country == "at") {
          if (!this.fieldsets2Okay[3] || !this.fieldsets2Okay[1]) {
            console.log("Issue because of Austrian Laws");
            abort = true;
          }
        } else if (this.$route.query.country == "de") {
          if (
            !this.fieldsets2Okay[0] ||
            !this.fieldsets2Okay[2] ||
            !this.fieldsets2Okay[1]
          ) {
            console.log("Issue because of German Laws");
            abort = true;
          }
        }

        if (abort) {
          console.log("Not okay.");
          this.showErrorMessage = true;
          this.errorMessage =
            "Bitte wählen Sie bei jedem Fieldset zumindest eine der Optionen aus.";
          const element = document.getElementById("two");
          element.scrollIntoView();
          return;
        }
      }

      this.loading = true;
      this.$store.state.fieldsets = this.fieldsets;
      axios
        .post(
          process.env.VUE_APP_BACKEND_DOMAIN + "create-account.php?step=4",
          {
            dienstort_inland: this.fieldsets.dienstort_inland,
            wohnsitzaenderung: this.fieldsets.wohnsitzaenderung,
            immobilienerwerb: this.fieldsets.immobilienerwerb,
            vermittlung: this.fieldsets.vermittlung,
            finanzplatz_austria: this.fieldsets.finanzplatz_austria,
            langfristige_veranlagung_inland:
              this.fieldsets.langfristige_veranlagung_inland,
            sonstige_1: this.fieldsets.sonstige_1,
            sonstige_1_text: this.fieldsets.sonstige_1_text,

            einkommen_unselbststaendig:
              this.fieldsets.selectedOption === "einkommen_unselbststaendig",
            einkommen_freiberuf:
              this.fieldsets.selectedOption === "einkommen_freiberuf",
            dividende_etc: this.fieldsets.selectedOption === "dividende_etc",
            virtuellen_etc: this.fieldsets.selectedOption === "virtuellen_etc",
            erbschaft_schenkung:
              this.fieldsets.selectedOption === "erbschaft_schenkung",
            verkauf_vermoegenswerte:
              this.fieldsets.selectedOption === "verkauf_vermoegenswerte",
            ablauf_von_vertraegen:
              this.fieldsets.selectedOption === "ablauf_von_vertraegen",
            sonstige_2: this.fieldsets.selectedOption === "sonstige_2",
            sonstige_2_text: this.fieldsets.sonstige_2_text,

            lohn_gehaltszettel: this.fieldsets.lohn_gehaltszettel,
            einkommenssteuererklaerung:
              this.fieldsets.einkommenssteuererklaerung,
            depotauszug: this.fieldsets.depotauszug,
            erbschaft: this.fieldsets.erbschaft,
            sonstige_vertraege: this.fieldsets.sonstige_vertraege,
            sonstige_3: this.fieldsets.sonstige_3,
            sonstige_3_text: this.fieldsets.sonstige_3_text,

            weitere_veranlagungen_geplant:
              this.fieldsets.weitere_veranlagungen_geplant,
            /* contact */
            contactCall: this.fieldsets.contactCall,
            contactEmail: this.fieldsets.contactEmail,
            contactCallAndEmail: this.fieldsets.contactCallAndEmail,
            /*             contactConsent: this.fieldsets.contactConsent,
             */ contactdisConsent: this.fieldsets.contactdisConsent,
            /* new fieldset 4 */
            akt_einkommen_unselbststaendig:
              this.fieldsets.selectedOption1 ===
              "akt_einkommen_unselbststaendig",
            akt_einkommen_unselbststaendig_amount:
              this.fieldsets.akt_einkommen_unselbststaendig_amount,
            akt_einkommen_unselbststaendig_employer:
              this.fieldsets.akt_einkommen_unselbststaendig_employer,
            akt_einkommen_freiberuf:
              this.fieldsets.selectedOption1 === "akt_einkommen_freiberuf",
            akt_dividende_etc:
              this.fieldsets.selectedOption1 === "akt_dividende_etc",
            kein_einkommen: this.fieldsets.selectedOption1 === "kein_einkommen",
            akt_sonstige: this.fieldsets.selectedOption1 === "akt_sonstige",
            sonstige_5_text: this.fieldsets.sonstige_5_text,
          },
          {
            headers: {
              Processid: this.guid,
            },
          }
        )
        .then((res) => {
          console.log(res.data);
          if (this.previewPerson2) {
            axios
              .post(
                process.env.VUE_APP_BACKEND_DOMAIN +
                  "create-account.php?step=4",
                {
                  person2: true,
                  dienstort_inland: this.fieldsets_2.dienstort_inland,
                  wohnsitzaenderung: this.fieldsets_2.wohnsitzaenderung,
                  immobilienerwerb: this.fieldsets_2.immobilienerwerb,
                  vermittlung: this.fieldsets_2.vermittlung,
                  finanzplatz_austria: this.fieldsets_2.finanzplatz_austria,
                  langfristige_veranlagung_inland:
                    this.fieldsets_2.langfristige_veranlagung_inland,
                  sonstige_1: this.fieldsets_2.sonstige_1,
                  sonstige_1_text: this.fieldsets_2.sonstige_1_text,

                  einkommen_unselbststaendig:
                    this.fieldsets_2.selectedOption ===
                    "einkommen_unselbststaendig",
                  einkommen_freiberuf:
                    this.fieldsets_2.selectedOption === "einkommen_freiberuf",
                  dividende_etc:
                    this.fieldsets_2.selectedOption === "dividende_etc",
                  virtuellen_etc:
                    this.fieldsets_2.selectedOption === "virtuellen_etc",
                  erbschaft_schenkung:
                    this.fieldsets_2.selectedOption === "erbschaft_schenkung",
                  verkauf_vermoegenswerte:
                    this.fieldsets_2.selectedOption ===
                    "verkauf_vermoegenswerte",
                  ablauf_von_vertraegen:
                    this.fieldsets_2.selectedOption === "ablauf_von_vertraegen",
                  sonstige_2: this.fieldsets_2.selectedOption === "sonstige_2",

                  sonstige_2_text: this.fieldsets_2.sonstige_2_text,
                  lohn_gehaltszettel: this.fieldsets_2.lohn_gehaltszettel,
                  einkommenssteuererklaerung:
                    this.fieldsets_2.einkommenssteuererklaerung,
                  depotauszug: this.fieldsets_2.depotauszug,
                  erbschaft: this.fieldsets_2.erbschaft,
                  sonstige_vertraege: this.fieldsets_2.sonstige_vertraege,
                  sonstige_3: this.fieldsets_2.sonstige_3,
                  sonstige_3_text: this.fieldsets_2.sonstige_3_text,

                  weitere_veranlagungen_geplant:
                    this.fieldsets_2.weitere_veranlagungen_geplant,
                  contactCall: this.fieldsets_2.contactCall,
                  contactEmail: this.fieldsets_2.contactEmail,
                  contactCallAndEmail: this.fieldsets_2.contactCallAndEmail,
                  /*                   contactConsent: this.fieldsets_2.contactConsent,
                   */ contactdisConsent: this.fieldsets_2.contactdisConsent,
                  /* new field set 4 */
                  akt_einkommen_unselbststaendig:
                    this.fieldsets_2.selectedOption1 ===
                    "akt_einkommen_unselbststaendig",
                  akt_einkommen_unselbststaendig_amount:
                    this.fieldsets_2.akt_einkommen_unselbststaendig_amount,
                  akt_einkommen_unselbststaendig_employer:
                    this.fieldsets_2.akt_einkommen_unselbststaendig_employer,
                  akt_einkommen_freiberuf:
                    this.fieldsets_2.selectedOption1 ===
                    "akt_einkommen_freiberuf",
                  akt_dividende_etc:
                    this.fieldsets_2.selectedOption1 === "akt_dividende_etc",
                  kein_einkommen:
                    this.fieldsets_2.selectedOption1 === "kein_einkommen",
                  akt_sonstige:
                    this.fieldsets_2.selectedOption1 === "akt_sonstige",
                  sonstige_5_text: this.fieldsets_2.sonstige_5_text,
                },
                {
                  headers: {
                    Processid: this.guid,
                  },
                }
              )
              .then((res) => {
                console.log(res.data);
                this.loading = false;
                this.$emit("confirmation");
              })
              .catch((e) => {
                console.error("Request failed:", e.response);
                this.loading = false;
              });
          } else {
            this.loading = false;
            this.$emit("confirmation");
          }
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
        });
    },
  },
};
</script>
