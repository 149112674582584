<template>
  <div
    v-if="!confirmedDatenschutz"
    class="bg-gray-200 min-w-full min-h-screen flex justify-center"
  >
    <ConfirmDatenschutz @confirmation="confirmedDatenschutz = true" />
  </div>
  <div v-else>
    <progress
      class="progress progress-primary w-full rounded-none"
      :value="progressVal"
      max="100"
    />
    <div class="flex justify-center">
      <div class="p-4 w-full sm:w-2/3 xl:w-1/2">
        <h1 v-if="confirmedSteps == 4" class="text-xl font-bold mb-3">
          {{ $t("dataConfirmation") }}
        </h1>
        <h1
          v-if="confirmedSteps == 3 && $route.query.country === 'at'"
          class="text-xl font-bold mb-3"
        >
          Herkunft des Veranlagungsbetrags
          <p style="font-size: small; font-weight: normal">
            Wir benötigen aufgrund rechtlicher Vorschriften folgende
            Informationen von Ihnen:
          </p>
        </h1>
        <h1 v-else class="text-xl font-bold mb-3">
          {{ $t("createAccount") }} ({{ $t("step") }} {{ confirmedSteps + 1 }})
        </h1>
        <StepOne @confirmation="confirmStepOne" :class="stepClass(1)" />
        <StepTwo
          @confirmation="confirmStepTwo"
          @goBack="confirmedSteps = 0"
          :guid="guid"
          :class="stepClass(2)"
        />
        <StepThree
          @confirmation="confirmStepThree"
          @goBack="confirmedSteps = 1"
          :guid="guid"
          :class="stepClass(3)"
        />
        <StepFour
          @confirmation="confirmStepFour"
          @goBack="confirmedSteps = 2"
          :guid="guid"
          :class="stepClass(4)"
        />
        <StepFourPointFive
          v-if="confirmedSteps == 4"
          :guid="guid"
          @confirmation="confirmStepFourPointFive"
          @goBack="confirmedSteps = 3"
          :class="stepClass(5)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import StepOne from "@/components/StepOne.vue";
import StepTwo from "@/components/StepTwo.vue";
import StepThree from "@/components/StepThree.vue";
import StepFour from "@/components/StepFour.vue";
import StepFourPointFive from "@/components/StepFourPointFive.vue";
import ConfirmDatenschutz from "@/components/ConfirmDatenschutz.vue";

export default {
  components: {
    ConfirmDatenschutz,
    StepOne,
    StepTwo,
    StepThree,
    StepFour,
    StepFourPointFive,
  },
  name: "HomeView",
  data() {
    return {
      guid: "",
      confirmedSteps: 0,
      maxSteps: 5,
      confirmedDatenschutz: false,
    };
  },
  methods: {
    confirmStepOne(guid) {
      console.log("Passed on: " + guid);
      this.guid = guid;
      this.confirmedSteps = 1;
      this.scrollToTop();
    },
    confirmStepTwo() {
      this.confirmedSteps = 2;
      this.scrollToTop();
    },
    confirmStepThree() {
      this.confirmedSteps = 3;
      this.scrollToTop();
    },
    confirmStepFour() {
      this.confirmedSteps = 4;
      this.scrollToTop();
    },
    confirmStepFourPointFive() {
      this.$router.push("/" + this.$i18n.locale + "/confirmation/" + this.guid);
    },

    stepClass(n) {
      if (n - 1 != this.confirmedSteps) {
        return "hidden";
      }

      return "";
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },
  computed: {
    stepsReady() {
      if (this.empfName && this.empfIban && this.empfBIC && this.empfBank) {
        return 3;
      }
      return 0;
    },
    progressVal() {
      return ((this.confirmedSteps + 1) / this.maxSteps) * 100;
    },
  },
  watch: {
    // uff this one could be unexpected af (sry)... gonna try to make some comments to make it more obvious
    // tags: [skip step three redirect router push router.push ...]
    // confirmedSteps() {
    // f me this was unexpected..........
    // if (this.$store.getters.isGermanAccountCreation && this.confirmedSteps == 3) {
    //   this.confirmedSteps = 2;
    // }
    // },
  },
};
</script>
