<template>
  <form @submit="sendStepOne" @submit.prevent="onSubmit" v-if="!loadingStepOne">
    <h2 class="font-bold mb-4">{{ $t("budgetAndTime") }}</h2>
    <div>
      <label class="block text-sm font-bold mb-2" for="betrag"
        >{{ $t("amount") }} in €
      </label>
      <input
        class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        id="betrag"
        v-model="betrag"
        :min="getMinBetrag()"
        type="number"
        required
        aria-required="true"
        step="1"
        :placeholder="$t('amount') + 'in €'"
      />
    </div>
    <div class="py-2">
      <Anlagedauer
        v-if="minMonths.length > 0"
        v-model="anlageDauerMonate"
        :options="minMonths"
      />
    </div>
    <div class="flex justify-end py-3">
      <div v-if="isReady || defIsReady">
        <button v-if="loading" class="btn btn-primary" aria-hidden="true">
          {{ $t("continue") }}
          <LoadingCircle />
        </button>
        <button v-else class="btn btn-primary" aria-hidden="true">
          {{ $t("continue") }}
        </button>
      </div>
      <button
        v-else
        class="btn btn-disabled"
        aria-hidden="true"
        disabled="true"
      >
        {{ $t("continue") }}
      </button>
    </div>
    <span v-if="errorMsg">{{ errorMsg }}</span>
  </form>
  <LoadingCircle v-else />
</template>

<script>
import axios from "axios";
import Anlagedauer from "@/components/Anlagedauer.vue";
import LoadingCircle from "@/components/LoadingCircle.vue";

export default {
  components: {
    Anlagedauer,
    LoadingCircle,
  },
  data() {
    return {
      betrag: this.getMinBetrag(),
      anlageDauerMonate: 0,
      loading: false,
      loadingStepOne: true,
      errorMsg: "",
      defIsReady: false,
      minMonths: [],
    };
  },
  watch: {
    anlageDauerMonate(newADM) {
      this.anlageDauerMonate = Number(newADM);
    },
  },
  computed: {
    isReady() {
      if (this.anlageDauerMonate != 0 && this.betrag != 0) {
        return true;
      }

      return false;
    },
  },
  async mounted() {
    if (this.$route.query.betrag) {
      this.betrag = Number(this.$route.query.betrag);
    }

    if (this.$route.query.anlagedauer) {
      this.anlageDauerMonate = Number(this.$route.query.anlagedauer);
    }

    if (this.$route.query.betrag && this.$route.query.anlageDauerMonate) {
      this.defIsReady = true;
    }

    if (this.$route.query.country == "") {
      this.$store.dispatch("ne  Response", {
        message: this.$t("missingCountry"),
        success: false,
      });
    }

    // load "options" (of months)
    axios
      .get(
        process.env.VUE_APP_ADMIN_BACKEND +
          `zinsRules?country=${this.$route.query.country}&mode=festgeld`
      )
      .then((res) => {
        console.log(res);
        res.data.zinsRules.forEach((zR) => {
          this.minMonths.push(zR.minMonths);
        });

        // here, this.anlageDauerMonate may be set to a value in the get parameter
        // if
        if (res.data.zinsRules.indexOf(String(this.anlageDauerMonate)) <= 0) {
          console.log("Changing anlagedauerMonate");
          this.anlageDauerMonate = Number(res.data.zinsRules[0].minMonths);
        }
        this.loadingStepOne = false;
      })
      .catch((e) => {
        console.log(e);
        this.$store.dispatch("newResponse", {
          message: e,
          success: false,
        });
        this.loadingStepOne = false;
      });
  },
  methods: {
    getMinBetrag() {
      // Check if the country is 'at', and return the appropriate minimum value
      return this.$route.query.country === "at" ? 1000 : 5000;
    },
    sendStepOne() {
      this.loading = true;
      axios
        .post(
          process.env.VUE_APP_BACKEND_DOMAIN + "create-account.php?step=1",
          {
            betrag: this.betrag,
            anlagedauer: this.anlageDauerMonate,
          }
        )
        .then((res) => {
          this.loading = false;

          // emit the guid at the confirmation event
          this.$emit("confirmation", res.data.guid);
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
        });
    },
  },
};
</script>
