<template>
  <div class="flex flex-col max-w-lg">
    <div class="bg-white p-6 m-2 sm:m-4 rounded shadow text-center">
      <h1 class="font-bold text-xl m-2">{{ $t("createAccount") }}</h1>
      <h2 class="italic">{{ $t("readFirst") }}:</h2>
      <form @submit="confirmDatenschutz">
        <div class="flex items-center">
          <input
            class="checkbox p-2"
            id="confirmDatenschutz"
            type="checkbox"
            required
            aria-required="true"
          />
          <label for="confirmDatenschutz" class="p-2 text-start">
            {{ $t("youAccept") }}
            <a :href="dataTermsLink" class="underline" target="_blank">
              {{ $t("dataTerms") }}
            </a>
            {{ $t("youAccept_1") }}
          </label>
        </div>
        <div class="flex items-center">
          <input
            class="checkbox p-2"
            id="confirmWiderruf"
            type="checkbox"
            required
            aria-required="true"
          />
          <label for="confirmWiderruf" class="p-2 text-start">
            {{ $t("youAccept2") }}
            <a
              v-if="$route.query.country == 'at'"
              :href="cancellationPolicyLink"
              class="underline"
              target="_blank"
            >
              {{ $t("recall_at") }} (Österreich)
            </a>

            <a
              v-if="$route.query.country == 'de'"
              href="/widerrufsbelehrung-vakifbank.pdf"
              class="underline"
              target="__blank"
              >{{ $t("recall") }} (Deutschland)</a
            >
            {{ $t("youAccept2_1") }}
          </label>
        </div>
        <div class="flex items-center">
          <input
            class="checkbox p-2"
            id="confirmDatenschutz2"
            type="checkbox"
            required
            aria-required="true"
          />
          <label for="confirmDatenschutz2" class="p-2 text-start">
            {{ $t("gotEverything") }}
          </label>
        </div>
        <button class="btn btn-primary m-2">
          {{ $t("iAccept") }}
        </button>
      </form>
    </div>
    <div class="bg-white p-6 m-2 sm:m-4 rounded shadow text-center">
      <h1 class="font-bold text-xl m-2">{{ $t("step4stepExplanaition") }}</h1>
      <h2 class="italic">
        {{ $t("stepForStepText") }}
      </h2>
      <div class="mt-3">
        <h3 class="font-bold text-lg">{{ $t("step1") }}</h3>
        <p>
          {{ $t("step1Explained") }}
        </p>
      </div>
      <div class="mt-3">
        <h3 class="font-bold text-lg">{{ $t("step2") }}</h3>
        <p>
          {{ $t("step2Explained") }}
        </p>
      </div>
      <div class="mt-3">
        <h3 class="font-bold text-lg">{{ $t("step3") }}</h3>
        <p>
          {{ $t("step3Explained") }}
        </p>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
export default {
  computed: {
    dataTermsLink() {
      const currentLanguage = this.$i18n.locale;
      if (currentLanguage === "en") {
        return "https://www.vakifbank.at/en/datenschutzerklarung/";
      } else if (currentLanguage === "de") {
        return "https://www.vakifbank.at/de/datenschutzerklarung/";
      } else {
        // Default to English version if language is neither "en" nor "de"
        return "https://www.vakifbank.at/en/datenschutzerklarung/";
      }
    },
    cancellationPolicyLink() {
      const currentLanguage = this.$i18n.locale;
      if (currentLanguage === "en") {
        return "/rucktrittsbelehrung_EN.pdf"; // English version of the cancellation policy
      } else if (currentLanguage === "de") {
        return "/rucktrittsbelehrung.pdf"; // German version of the cancellation policy
      } else {
        // Default to German version if language is neither "en" nor "de"
        return "/rucktrittsbelehrung.pdf";
      }
    },
  },

  methods: {
    confirmDatenschutz() {
      this.$emit("confirmation");
    },
  },
};
</script>
