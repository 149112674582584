<template>
  <CMBreadCrumb
    :children="[
      { text: 'Accounts', link: '/admin/accounts' },
      { text: 'Account', link: '/admin/account/' + $route.params.guid },
    ]"
  />
  <form @submit="updateAccount" @submit.prevent="onSubmit">
    <div class="flex p-1">
      <CMInput
        class="flex-1 m-1"
        label="Vorname:"
        v-model="account.firstName"
        :required="true"
      />
      <CMInput
        class="flex-1 m-1"
        label="Nachname:"
        v-model="account.lastName"
        :required="true"
      />
    </div>
    <CMInput
      class="flex-1 m-1"
      label="E-Mail:"
      type="email"
      v-model="account.email"
      :required="true"
    />
    <section id="adminstuff" v-if="$store.getters.getJwtDecoded.isAdmin">
      <div class="p-2">
        <h3 class="font-semibold text-sm">Zugriff auf Länder:</h3>
        <div class="flex justify-start flex-col sm:flex-row">
          <div class="sm:me-2 sm:my-2">
            <label
              for="austria"
              class="rounded-lg p-2 my-2 block transition-all cursor-pointer border"
              :class="account.austria ? ' bg-green-400 ' : 'hover:bg-green-200'"
              >{{ $t("aut") }} 🇦🇹</label
            >
            <input
              type="checkbox"
              id="austria"
              class="hidden"
              v-model="account.austria"
            />
          </div>
          <div class="sm:m-2">
            <label
              for="germany"
              class="rounded-lg p-2 my-2 block transition-all cursor-pointer border"
              :class="account.germany ? ' bg-green-400 ' : 'hover:bg-green-200'"
              >{{ $t("ger") }} 🇩🇪</label
            >
            <input
              type="checkbox"
              id="germany"
              class="hidden"
              v-model="account.germany"
            />
          </div>
          <div class="sm:m-2">
            <label
              for="hungary"
              class="rounded-lg p-2 my-2 block transition-all cursor-pointer border"
              :class="account.hungary ? ' bg-green-400 ' : 'hover:bg-green-200'"
              >{{ $t("hungary") }} 🇭🇺</label
            >
            <input
              type="checkbox"
              id="hungary"
              class="hidden"
              v-model="account.hungary"
            />
          </div>
        </div>
      </div>
      <div class="flex flex-col">
        <h2 class="font-bold">Sicherheitslevel:</h2>
        <div class="flex align-middle p-2">
          <Switch
            id="adminswitch"
            v-model="account.admin"
            class="mt-0"
            :class="[
              account.admin ? 'bg-primary' : 'bg-gray-200',
              'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-secondary focus:ring-offset-2',
            ]"
          >
            <span class="sr-only">Sollte Admin Sein Toggle</span>
            <span
              aria-hidden="true"
              :class="[
                account.admin ? 'translate-x-5' : 'translate-x-0',
                'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
              ]"
            />
          </Switch>
          <label for="adminswitch" class="ms-2 italic font-semibold text-sm">{{
            account.admin ? "Ist Admin" : "Kein Admin"
          }}</label>
        </div>
      </div>
    </section>
    <button class="btn btn-primary my-2 text-xs">Speichern</button>
    <div v-if="$store.getters.getJwtDecoded.isAdmin">
      <p class="underline text-xs hover:cursor-pointer" @click="showAccDel = !showAccDel">
        Account löschen
      </p>
      <div v-if="showAccDel">
        Sind sie sicher dass Sie diesen Account deaktivieren möchten?
        <p>
          Wenn Sie sich sicher sind, dann klicken Sie
          <button @click="deleteUser" class="underline text-xs hover:cursor-pointer">
            hier
          </button>
        </p>
      </div>
    </div>
  </form>
</template>

<script setup>
import { Switch } from "@headlessui/vue";
</script>

<script>
import axios from "axios";
import CMInput from "@/components/CMInput.vue";
import CMBreadCrumb from "@/components/CMBreadCrumb.vue";
// import { toRaw } from "vue";

export default {
  components: {
    CMInput,
    CMBreadCrumb,
  },
  data() {
    return {
      account: {},
      initialAdminStatus: false,
      showAccDel: false,
    };
  },
  created() {
    this.loadAccount(this.$route.params.guid);
  },
  methods: {
    deleteUser() {
      if (!confirm("Bist du sicher, dass du das Admin Recht verändern möchtest?")) {
        this.$store.dispatch("newResponse", {
          message: "Erfolgreich abgebrochen",
          success: true,
        });
        this.showAccDel = false;
        return;
      }
      axios
        .delete(process.env.VUE_APP_ADMIN_BACKEND + "user/" + this.account.guid, {
          headers: {
            "x-access-token": this.$store.getters.getJwt,
          },
        })
        .then((res) => {
          this.$store.dispatch("newResponse", {
            message: "Erfolgreich Account deaktiviert",
            success: true,
          });
          console.log(res);
          this.$router.push("/admin/accounts");
        })
        .catch((e) => {
          console.log(e);
          this.$store.dispatch("newResponse", {
            message: "Ein Fehler ist aufgetreten",
            success: false,
          });
          if (e.response.status == 403) {
            this.$router.push("/login");
          }
        });
    },
    updateAccount() {
      // make it possible to abort before error
      console.log("Initial Admin Status:" + this.initialAdminStatus);
      if (this.account.admin != this.initialAdminStatus) {
        if (!confirm("Bist du sicher, dass du das Admin Recht verändern möchtest?")) {
          this.$store.dispatch("newResponse", {
            message: "Erfolgreich abgebrochen",
            success: true,
          });

          this.account.admin = this.initialAdminStatus;

          return;
        }
      }
      axios
        .patch(
          process.env.VUE_APP_ADMIN_BACKEND + "user/" + this.account.guid,
          {
            email: this.account.email,
            firstName: this.account.firstName,
            lastName: this.account.lastName,
            admin: this.account.admin,
            austria: this.account.austria,
            germany: this.account.germany,
            hungary: this.account.hungary,
          },
          {
            headers: {
              "x-access-token": this.$store.getters.getJwt,
            },
          }
        )
        .then((res) => {
          console.log(res);
          this.account = res.data.user;
          this.initialAdminStatus = this.account.admin;

          this.$store.dispatch("newResponse", {
            message: res.data.msg || "Erfolgreich Account aktualisiert",
            success: true,
          });
        })
        .catch((e) => {
          console.log(e);
          this.$store.dispatch("newResponse", {
            message: e.response.data,
            success: false,
          });
          if (e.response.status == 403) {
            this.$router.push("/login");
          }
        });
    },
    loadAccount(guid) {
      axios
        .get(process.env.VUE_APP_ADMIN_BACKEND + "user/" + guid, {
          headers: {
            "x-access-token": this.$store.getters.getJwt,
          },
        })
        .then((res) => {
          this.$store.dispatch("newResponse", {
            message: "Erfolgreich Account geladen",
            success: true,
          });
          this.account = res.data;
          this.initialAdminStatus = this.account.admin;
        })
        .catch((e) => {
          console.log(e);
          this.$store.dispatch("newResponse", {
            message: "Ein Fehler ist aufgetreten",
            success: false,
          });
          if (e.response.status == 403) {
            this.$router.push("/login");
          }
        });
    },
  },
};
</script>
