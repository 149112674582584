<template>
  <EasyNav />
  <router-view />
</template>

<script>
import EasyNav from "@/components/EasyNav.vue";
export default {
  components: {
    EasyNav,
  },
};
</script>
