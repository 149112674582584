<template>
  <form @submit="confirmDataStepFpV" @submit.prevent="onSubmit">
    <h2 class="mb-4">Bitte überprüfen Sie Ihre Angaben noch einmal genau:</h2>
    <div v-if="previewPerson1">
      <StepTwoForm :disabled="true" :proxyPerson="previewPerson1" />
    </div>
    <div v-if="previewPerson2">
      <h2 class="font-bold mb-4">
        Persönliche Angaben für zweite Person (Gemeinschaftskonto)
      </h2>
      <StepTwoForm :disabled="true" :proxyPerson="previewPerson2" />
    </div>
    <div>
      <h2 class="font-bold mb-4 text-xl">Konto Informationen:</h2>
      <StepThreeForm :disabled="true" :proxyData="$store.state.bankInfo" />
    </div>
    <div v-if="!$store.getters.isGermanAccountCreation">
      <h2 class="font-bold mb-4 text-xl">Fragebogen:</h2>
      <h2
        v-if="previewPerson1"
        class="font-bold bg-gray-400 text-center text-white rounded p-1"
      >
        Angaben für {{ previewPerson1.firstName }}
        {{ previewPerson1.lastName }}:
      </h2>
      <FieldsetsStepFour
        v-if="loaded"
        :disabled="true"
        :proxyFieldset="$store.state.fieldsets"
      />
      <div v-if="previewPerson2">
        <h2 class="font-bold bg-gray-400 text-center text-white rounded p-1">
          Angaben für {{ previewPerson2.firstName }}
          {{ previewPerson2.lastName }}:
        </h2>
        <FieldsetsStepFour
          v-if="loaded"
          :disabled="true"
          :proxyFieldset="$store.state.fieldsets_2"
        />
      </div>
    </div>
    <p class="font-bold mt-4">Alle Daten korrekt eingegeben?</p>
    <div class="flex align-middle items-center">
      <input
        class="checkbox"
        id="check413"
        type="checkbox"
        required
        aria-required="true"
      />
      <label class="block text-sm p-4" for="check413">
        Ich erkläre mich mit den
        <a
          class="underline"
          target="_blank"
          href="https://webid-solutions.de/agb/"
          >AGB der WebID Solutions GmbH („WebID“)</a
        >
        einverstanden. Ausführliche Informationen zum Umgang mit Ihren
        personenbezogenen Daten durch WebID können Sie unserer
        <a
          class="underline"
          target="_blank"
          href="https://webid-solutions.de/datenschutzbestimmungen/"
          >Datenschutzerklärung</a
        >
        entnehmen. Wir weisen darauf hin, dass ggf. auch biometrische Daten für
        die
        <a
          class="underline"
          target="_blank"
          href="https://webid-solutions.de/datenschutzbestimmungen/#datenverarbeitung"
          >hier</a
        >
        beschriebenen Zwecke verarbeitet werden.</label
      >
    </div>

    <div
      v-if="$route.query.country == 'de'"
      class="flex align-middle items-center"
    >
      <input
        class="checkbox"
        id="check414"
        type="checkbox"
        required
        aria-required="true"
      />
      <label class="block text-sm p-4" for="check414">
        Ich erkläre mich mit den
        <a class="underline" target="_blank" href="https://vakifbank.de/de/agb/"
          >AGB</a
        >,
        <a
          class="underline"
          target="_blank"
          href="https://vakifbank.de/de/einlagensicherung/"
          >Einlagensicherung</a
        >
        und
        <a
          class="underline"
          target="_blank"
          href="https://vakifbank.de/de/glossar-und-bedingungen/"
          >Besondere Bedingungen</a
        >
        der Vakifbank AG einverstanden.
      </label>
    </div>

    <div class="flex justify-between py-3">
      <button type="button" class="btn btn-grey" @click="$emit('goBack')">
        Zurück
      </button>
      <button v-if="loading" class="btn btn-primary">
        {{ loadingText }} <LoadingCircle />
      </button>
      <button v-else class="btn btn-primary">Senden</button>
    </div>
  </form>
</template>

<script>
import FieldsetsStepFour from "./FieldsetsStepFour.vue";
import StepThreeForm from "./StepThreeForm.vue";
import LoadingCircle from "./LoadingCircle.vue";
import StepTwoForm from "./StepTwoForm.vue";
import { toRaw } from "vue";
import axios from "axios";

export default {
  components: {
    FieldsetsStepFour,
    StepThreeForm,
    LoadingCircle,
    StepTwoForm,
  },
  props: {
    guid: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      previewPerson1: {},
      previewPerson2: undefined,
      previewFieldsets: {},
      loaded: false,
      loading: false,
      crifSuccess: false,
      loadingText: "Einen Moment...",
    };
  },
  methods: {
    async coolLoadingText() {
      await this.Sleep(7500);
      this.loadingText = "Danke für Ihre Geduld...";

      await this.Sleep(7500);
      this.loadingText = "Fast Fertig...";
    },
    startCrifAuth() {
      this.coolLoadingText();
      return axios
        .get(
          process.env.VUE_APP_BACKEND_DOMAIN + "create-account.php?crif=true",
          {
            headers: {
              Processid: this.guid,
            },
          }
        )
        .then((res) => {
          console.log(res.data);
          this.loadingCRIF = false;
          this.crifSuccess = true;
        })
        .catch((e) => {
          console.log(e);
          this.loadingCRIF = false;
          this.crifSuccess = false;
        });
    },
    async confirmDataStepFpV() {
      this.loading = true;
      await this.startCrifAuth();
      this.loading = false;
      if (this.crifSuccess) {
        this.$emit("confirmation");
      }
    },
    Sleep(milliseconds) {
      return new Promise((resolve) => setTimeout(resolve, milliseconds));
    },
  },
  async created() {
    console.log(`Trying to display disabled Preview Data for Step4.5`);
    console.log(toRaw(this.$store.state.persons));
    this.previewPerson1 = toRaw(this.$store.state.persons[0]);

    // check if person2 really exists...
    let p2 = toRaw(this.$store.state.persons[1]);
    console.log("xxx:", p2.firstName);
    if (p2.firstName != undefined && p2.firstName != "") {
      this.previewPerson2 = p2;
    } else {
      this.previewPerson2 = null;
    }

    this.loaded = true;
  },
};
</script>
