<template>
  <div>
    <section>
      <h1 class="font-bold text-xl">Zinsen bearbeiten</h1>
      <div v-if="zinsen.length == 0">Keine Zinsen gefunden... 📚</div>
      <div v-else>
        <div v-if="austrianZinsen.length != 0">
          <h2 class="font-bold">Österreichische Zinssätze 🇦🇹</h2>
          <ZinsPreview
            v-for="austrianZins in austrianZinsen"
            :key="austrianZins"
            :zinsSetting="austrianZins"
          />
        </div>
        <div v-if="germanZinsen.length != 0">
          <h2 class="font-bold">Deutsche Zinssätze 🇩🇪</h2>
          <ZinsPreview
            v-for="germanZins in germanZinsen"
            :key="germanZins"
            :zinsSetting="germanZins"
          />
        </div>
        <div v-if="hungarianZinsen.length != 0">
          <h2 class="font-bold">Ungarische Zinssätze 🇭🇺</h2>
          <ZinsPreview
            v-for="hungarianZins in hungarianZinsen"
            :key="hungarianZins"
            :zinsSetting="hungarianZins"
          />
        </div>
      </div>
    </section>
    <section class="border rounded-lg shadow p-4 mt-4 border-orange-200 bg-orange-100">
      <form @submit="createZinsSetting" @submit.prevent="onSubmit">
        <h3 class="underline pb-2 text-lg font-bold">Zinssatz erstellen:</h3>
        <CMInput
          class="mb-3"
          label="Ersteller:"
          disabled="true"
          type="text"
          v-model="$store.getters.getJwtDecoded.name"
          description="Der Ersteller kann nicht geändert werden. Bitte sei vorsichtig bei Erstellung der Regel und überprüfe deine Eingaben genau."
        />
        <div class="my-2">
          <label for="countries" class="block mb-2 text-sm font-medium text-gray-900"
            >Für welches Land sollen diese Zinsen zutreffen?</label
          >
          <p id="countryexaplin" class="text-sm">Bitte wähle ein Land:</p>
          <select
            id="countries"
            aria-describedby="countryexaplin"
            v-model="neueZinsen.country"
            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
          >
            <option value="de">Deutschland 🇩🇪</option>
            <option value="at">Österreich 🇦🇹</option>
            <option value="hu">Ungarn 🇭🇺</option>
          </select>
        </div>
        <CMInput
          type="text"
          label="Modus (Achtung):"
          v-model="neueZinsen.mode"
          description="Modus wie 'Festgeld' (Termingeld) oder 'Sparbuch'."
        />
        <button
          type="submit"
          class="inline-flex items-center rounded-md bg-orange-200 border text-orange-600 border-red-500 px-3 py-2 text-sm font-semibold shadow-sm hover:bg-opacity-80 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 hover:scale-95 mt-2 transition-all"
        >
          Neue Zinsen anlegen
        </button>
      </form>

      <p class="italic font-sm my-2">
        Nach Erstellung der Zinsen ist die Regel inaktiv und kann (und sollte unbedingt)
        noch vor Live-Schaltung getestet werden ⚠️
      </p>
    </section>
  </div>
</template>

<script>
import axios from "axios";
import CMInput from "@/components/CMInput.vue";
import ZinsPreview from "@/components/ZinsPreview.vue";

export default {
  components: { CMInput, ZinsPreview },
  data() {
    return {
      zinsen: [],
      germanZinsen: [],
      austrianZinsen: [],
      hungarianZinsen: [],
      neueZinsen: {},
      newRuleMinMonth: 1, // 1 at the beginning, with every rule you add this gets updated.
    };
  },
  methods: {
    loadZinsen() {
      axios
        .get(process.env.VUE_APP_ADMIN_BACKEND + "zinsen", {
          headers: {
            "x-access-token": this.$store.getters.getJwt,
          },
        })
        .then((res) => {
          this.$store.dispatch("newResponse", {
            message: "Erfolgreich Zinsen geladen",
            success: true,
            time: 5000,
          });
          console.log(res);
          this.zinsen = res.data.zinsen;
          if (res.data.austrianZinsen) {
            this.austrianZinsen = res.data.austrianZinsen;
          }

          if (res.data.germanZinsen) {
            this.germanZinsen = res.data.germanZinsen;
          }

          if (res.data.hungarianZinsen) {
            this.hungarianZinsen = res.data.hungarianZinsen;
          }
        })
        .catch((e) => {
          this.$store.dispatch("newResponse", {
            message: "Zinsen konnten nicht geladen werden...",
            success: false,
            time: 5000,
          });
          if (e.response.status == 403) {
            this.$router.push("/login");
          }
          console.log(e);
        });
    },
    createZinsSetting() {
      axios
        .post(
          process.env.VUE_APP_ADMIN_BACKEND + "zinsSetting",
          {
            country: this.neueZinsen.country,
            mode: this.neueZinsen.mode.toLowerCase(),
          },
          {
            headers: {
              "x-access-token": this.$store.getters.getJwt,
            },
          }
        )
        .then((res) => {
          this.$store.dispatch("newResponse", {
            message: "Erfolgreich Zinsen erstellt",
            success: true,
          });
          this.$router.push("/admin/zinsen/" + res.data.id);
        })
        .catch((e) => {
          console.log(e);
          this.$store.dispatch("newResponse", {
            message: `Ein Fehler ist aufgetreten (${e.response ? e.response.data : e})`,
            success: false,
          });
          if (e.response.status == 403) {
            this.$router.push("/login");
          }
        });
    },
  },
  created() {
    this.loadZinsen();
  },
};
</script>
