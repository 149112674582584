<template>
  <div class="mb-4">
    <fieldset
      v-if="$route.query.country === 'de' && fieldsets && loaded"
      :class="fieldsetClass(0)"
    >
      <legend class="font-bold">
        {{ $t("backgroundOfRelation") }}
      </legend>
      <p class="text-sm italic">{{ $t("chooseOptionNicely") }}:</p>
      <div class="flex align-middle items-center">
        <input
          class="checkbox"
          :id="'check1' + idAddon"
          v-model="fieldsets.dienstort_inland"
          :disabled="disabled"
          type="checkbox"
        />
        <label
          class="block text-sm py-3 px-2"
          :for="forPrefix + 'check1' + idAddon"
          >{{ $t("workLoactionThingy") }}</label
        >
      </div>
      <div class="flex align-middle items-center">
        <input
          class="checkbox"
          :id="'check2' + idAddon"
          v-model="fieldsets.wohnsitzaenderung"
          :disabled="disabled"
          type="checkbox"
        />
        <label
          class="block text-sm py-3 px-2"
          :for="forPrefix + 'check2' + idAddon"
          >{{ $t("locationChange") }}</label
        >
      </div>
      <div class="flex align-middle items-center">
        <input
          class="checkbox"
          :id="'check3' + idAddon"
          v-model="fieldsets.immobilienerwerb"
          :disabled="disabled"
          type="checkbox"
        />
        <label
          class="block text-sm py-3 px-2"
          :for="forPrefix + 'check3' + idAddon"
          >{{ $t("buyRealEstate") }}</label
        >
      </div>
      <div class="flex align-middle items-center">
        <input
          class="checkbox"
          :id="'check4' + idAddon"
          v-model="fieldsets.vermittlung"
          :disabled="disabled"
          type="checkbox"
        />
        <label
          class="block text-sm py-3 px-2"
          :for="forPrefix + 'check4' + idAddon"
          >{{ $t("mediationBy") }}</label
        >
      </div>
      <div class="flex align-middle items-center">
        <input
          class="checkbox"
          :id="'check5' + idAddon"
          v-model="fieldsets.finanzplatz_austria"
          :disabled="disabled"
          type="checkbox"
        />
        <label
          class="block text-sm py-3 px-2"
          :for="forPrefix + 'check5' + idAddon"
          >{{ $t("austriaAsSafePlace") }}
        </label>
      </div>
      <div class="flex align-middle items-center">
        <input
          class="checkbox"
          :id="'langfristige_veranlagung_inland' + idAddon"
          v-model="fieldsets.langfristige_veranlagung_inland"
          :disabled="disabled"
          type="checkbox"
        />
        <label
          class="block text-sm py-3 px-2"
          :for="forPrefix + 'langfristige_veranlagung_inland' + idAddon"
          >{{ $t("longtermInvestmentsInland") }}
        </label>
      </div>
      <div class="flex align-middle items-center">
        <input
          class="checkbox"
          :id="'check6' + idAddon"
          v-model="fieldsets.sonstige_1"
          :disabled="disabled"
          type="checkbox"
        />
        <label
          class="block text-sm py-3 px-2"
          :for="forPrefix + 'check6' + idAddon"
          >{{ $t("others") }}
        </label>
      </div>
      <div v-if="fieldsets.sonstige_1">
        <label
          class="block text-sm font-bold mb-2"
          :for="forPrefix + 'sonstige_1_text' + idAddon"
          >{{ $t("others") }} ({{ $t("explanation") }}):</label
        >
        <input
          class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          :id="'sonstige_1_text' + idAddon"
          v-model="fieldsets.sonstige_1_text"
          :disabled="disabled"
          type="text"
          required
          aria-required="true"
          :placeholder="$t('description')"
        />
      </div>
    </fieldset>
    <!-- fieldset 4 -->
    <fieldset v-if="$route.query.country == 'at'" :class="fieldsetClass(3)">
      <p class="text-sm italic">{{ $t("chooseOptionNicely") }}:</p>
      <legend class="font-bold">
        {{ $t("akt_originOfFunds") }}
      </legend>
      <!-- Dropdown for akt_originOfFunds -->
      <select
        v-model="fieldsets.selectedOption1"
        :disabled="disabled"
        style="border-radius: 5px"
      >
        <option value="akt_einkommen_unselbststaendig">
          {{ $t("akt_concurringIncome") }}
        </option>
        <option value="akt_einkommen_freiberuf">
          {{ $t("akt_independentConcurringIncome") }}
        </option>
        <option value="akt_dividende_etc">{{ $t("akt_threeOptions") }}</option>
        <option value="akt_sonstige">{{ $t("akt_other") }}</option>
        <option value="kein_einkommen">{{ $t("kein_einkommen") }}</option>
      </select>

      <!-- Display additional input for akt_sonstige -->
      <!--     <div v-if="fieldsets.selectedOption1 === 'akt_sonstige'">
        <label
          class="block text-sm font-bold mb-2"
          :for="'sonstige_5_text' + idAddon"
        >
          {{ $t("akt_other") }} ({{ $t("explanation") }}):
        </label>
        <input
          class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          :id="'sonstige_5_text' + idAddon"
          v-model="fieldsets.sonstige_5_text"
          :disabled="disabled"
          type="text"
          required
          aria-required="true"
          :placeholder="$t('description')"
        />
      </div> -->

      <!-- Additional input for akt_einkommen_unselbststaendig -->
      <div v-if="fieldsets.selectedOption1 !== 'kein_einkommen'">
        <label
          class="block text-sm font-bold mb-2"
          :for="'akt_einkommen_unselbststaendig_amount' + idAddon"
        >
          {{ $t("monthlyAmount") }}:
        </label>
        <input
          class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          :id="'akt_einkommen_unselbststaendig_amount' + idAddon"
          v-model="fieldsets.akt_einkommen_unselbststaendig_amount"
          :disabled="disabled"
          required
          type="text"
          @input="restrictToNumbers"
        />
      </div>

      <div
        v-if="fieldsets.selectedOption1 === 'akt_einkommen_unselbststaendig'"
      >
        <label
          class="block text-sm font-bold mb-2"
          :for="'akt_einkommen_unselbststaendig_employer' + idAddon"
        >
          {{ $t("employer") }}:
        </label>
        <input
          class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          :id="'akt_einkommen_unselbststaendig_employer' + idAddon"
          v-model="fieldsets.akt_einkommen_unselbststaendig_employer"
          required
          :disabled="disabled"
          type="text"
        />
      </div>
      <!-- ... Ajoutez d'autres champs selon vos besoins ... -->
    </fieldset>

    <fieldset :class="fieldsetClass(1)">
      <p class="text-sm italic">{{ $t("chooseOptionNicely") }}:</p>
      <legend class="font-bold">
        {{ $t("originOfFunds") }}
      </legend>
      <!-- Dropdown for originOfFunds -->
      <select
        v-model="fieldsets.selectedOption"
        :disabled="disabled"
        style="border-radius: 5px"
      >
        <option value="einkommen_unselbststaendig">
          {{ $t("concurringIncome") }}
        </option>
        <option value="einkommen_freiberuf">
          {{ $t("independentConcurringIncome") }}
        </option>
        <option value="dividende_etc">{{ $t("threeOptions") }}</option>
        <option value="virtuellen_etc">{{ $t("fourOptions") }}</option>
        <option value="erbschaft_schenkung">{{ $t("twoOptions") }}</option>
        <option value="verkauf_vermoegenswerte">
          {{ $t("sellingOfAssets") }}
        </option>
        <option value="ablauf_von_vertraegen">
          {{ $t("endOfInsurance") }}
        </option>
        <option value="sonstige_2">{{ $t("others") }}</option>
      </select>

      <!-- Display additional input for sonstige_2 -->
      <div v-if="fieldsets.selectedOption === 'sonstige_2'">
        <label
          class="block text-sm font-bold mb-2"
          :for="'sonstige_2_text' + idAddon"
        >
          {{ $t("others") }} ({{ $t("explanation") }}):
        </label>
        <input
          class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          :id="'sonstige_2_text' + idAddon"
          v-model="fieldsets.sonstige_2_text"
          :disabled="disabled"
          type="text"
          required
          aria-required="true"
          :placeholder="$t('description')"
        />
      </div>
    </fieldset>

    <!-- new question for contact -->
    <fieldset v-if="$route.query.country == 'de'" :class="fieldsetClass(2)">
      <legend class="font-bold">{{ $t("howToBeContacted") }}</legend>
      <div class="flex align-middle items-center">
        <input
          class="checkbox"
          :id="'contactCall' + idAddon"
          v-model="fieldsets.contactCall"
          :disabled="disabled"
          type="checkbox"
          @change="checkSingleContactCheckbox('contactCall')"
        />
        <label
          class="block text-sm py-3 px-2"
          :for="forPrefix + 'contactCall' + idAddon"
        >
          {{ $t("call_contact") }}
        </label>
      </div>
      <div class="flex align-middle items-center">
        <input
          class="checkbox"
          :id="'contactEmail' + idAddon"
          v-model="fieldsets.contactEmail"
          :disabled="disabled"
          type="checkbox"
          @change="checkSingleContactCheckbox('contactEmail')"
        />
        <label
          class="block text-sm py-3 px-2"
          :for="forPrefix + 'contactEmail' + idAddon"
        >
          {{ $t("contactByEmail") }}
        </label>
      </div>
      <div class="flex align-middle items-center">
        <input
          class="checkbox"
          :id="'contactCallAndEmail' + idAddon"
          v-model="fieldsets.contactCallAndEmail"
          :disabled="disabled"
          type="checkbox"
          @change="checkSingleContactCheckbox('contactCallAndEmail')"
        />
        <label
          class="block text-sm py-3 px-2"
          :for="forPrefix + 'contactCallAndEmail' + idAddon"
        >
          {{ $t("callAndContactByEmail") }}
        </label>
      </div>
      <div class="flex align-middle items-center">
        <input
          class="checkbox"
          :id="'contactdisConsent' + idAddon"
          v-model="fieldsets.contactdisConsent"
          :disabled="disabled"
          type="checkbox"
          @change="checkSingleContactCheckbox('contactdisConsent')"
        />
        <label
          class="block text-sm py-3 px-2"
          :for="forPrefix + 'contactdisConsent' + idAddon"
        >
          {{ $t("dis_iAgree") }}
        </label>
      </div>
      <p class="text-sm py-2 px-2">
        Diese Einwilligungserklärung ist freiwillig und kann jederzeit – ohne
        Einfluss auf das Vertragsverhältnis – widerrufen werden.
      </p>
    </fieldset>

    <fieldset class="mb-2">
      <legend class="font-bold">{{ $t("further") }}</legend>
      <div class="flex align-middle items-center">
        <input
          class="checkbox"
          :id="'weitere_veranlagungen_geplant' + idAddon"
          v-model="fieldsets.weitere_veranlagungen_geplant"
          :disabled="disabled"
          type="checkbox"
        />
        <label
          class="block text-sm py-3 px-2"
          :for="forPrefix + 'weitere_veranlagungen_geplant' + idAddon"
        >
          {{ $t("iPlanMoreInvestments") }}
        </label>
      </div>
    </fieldset>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loaded: false,
      fieldsets: {
        // fieldset 0
        dienstort_inland: false,
        wohnsitzaenderung: false,
        immobilienerwerb: false,
        vermittlung: false,
        finanzplatz_austria: false,
        langfristige_veranlagung_inland: false,
        sonstige_1: false,
        sonstige_1_text: "",

        // fieldset 1
        einkommen_unselbststaendig: false,
        einkommen_freiberuf: false,
        dividende_etc: false,
        virtuellen_etc: false,
        erbschaft_schenkung: false,
        verkauf_vermoegenswerte: false,
        ablauf_von_vertraegen: false,
        sonstige_2: false,
        sonstige_2_text: "",

        // fieldset 2
        lohn_gehaltszettel: false,
        einkommenssteuererklaerung: false,
        depotauszug: false,
        erbschaft: false,
        sonstige_vertraege: false,
        sonstige_3: false,
        sonstige_3_text: "",

        // fieldset 3
        selectedOption: "",
        selectedOption1: "",
        // fieldset
        weitere_veranlagungen_geplant: false,
        //field set for contact
        contactCall: false,
        contactEmail: false,
        contactCallAndEmail: false,
        contactdisConsent: false,
        //fieldset 4
        akt_einkommen_unselbststaendig: false,
        akt_einkommen_freiberuf: false,
        akt_dividende_etc: false,
        kein_einkommen: false,
        akt_sonstige: false,
        sonstige_5_text: "",
        akt_einkommen_unselbststaendig_amount: "",
        akt_einkommen_unselbststaendig_employer: "",
      },
    };
  },
  methods: {
    fieldsetClass(n) {
      let fieldsetClass = "mb-2 border p-3 rounded-lg";
      if (this.showErrorMessage && !this.fieldsetsOkay[n]) {
        fieldsetClass = fieldsetClass + " bg-red-100";
      }

      return fieldsetClass;
    } /* to choose one option for contact field */,
    checkSingleContactCheckbox(checkboxName) {
      if (this.fieldsets[checkboxName]) {
        for (const field in this.fieldsets) {
          if (field !== checkboxName && field.startsWith("contact")) {
            this.fieldsets[field] = false;
          }
        }
      }
    },
    restrictToNumbers(event) {
      // Remove non-numeric characters using a regular expression
      const inputValue = event.target.value.replace(/[^0-9]/g, "");

      // Update the input value with the filtered numeric input
      this.fieldsets.akt_einkommen_unselbststaendig_amount = inputValue;
    },
  },
  props: {
    proxyFieldset: {
      type: Object,
      default: () => {},
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    showErrorMessage: {
      type: Boolean,
      default: false,
    },
    idAddon: {
      type: String,
      default: "",
    },
  },
  computed: {
    // due to some bug we need to change the for field so it does not match the id
    // of the input element to prevent a toggle...
    forPrefix() {
      if (this.disabled) {
        return "_";
      }
      return "";
    },
    fieldsetsOkay() {
      let returnObject = [false, false, false, false, false];

      if (
        this.fieldsets.dienstort_inland ||
        this.fieldsets.wohnsitzaenderung ||
        this.fieldsets.immobilienerwerb ||
        this.fieldsets.vermittlung ||
        this.fieldsets.finanzplatz_austria ||
        this.fieldsets.langfristige_veranlagung_inland ||
        this.fieldsets.sonstige_1
      ) {
        returnObject[0] = true;
      }

      if (
        this.fieldsets.selectedOption === "einkommen_unselbststaendig" ||
        this.fieldsets.selectedOption === "einkommen_freiberuf" ||
        this.fieldsets.selectedOption === "dividende_etc" ||
        this.fieldsets.selectedOption === "virtuellen_etc" ||
        this.fieldsets.selectedOption === "erbschaft_schenkung" ||
        this.fieldsets.selectedOption === "verkauf_vermoegenswerte" ||
        this.fieldsets.selectedOption === "ablauf_von_vertraegen" ||
        this.fieldsets.selectedOption === "sonstige_2"
      ) {
        returnObject[1] = true;
      }

      /**
       * NOTICE: IF NEEDED TO GET "Nachweis der Einkünfte / Vermögen - Beibringen von Dokumenten (Mehrfachauswahl)" back,
       * look at the github commits before 23.03.23 :)
       */

      if (
        this.fieldsets.contactCall || // Include the contact options here
        this.fieldsets.contactEmail ||
        this.fieldsets.contactCallAndEmail ||
        this.fieldsets.contactdisConsent
      ) {
        returnObject[2] = true;
      }
      if (
        this.fieldsets.selectedOption1 === "akt_einkommen_unselbststaendig" ||
        this.fieldsets.selectedOption1 === "akt_einkommen_freiberuf" ||
        this.fieldsets.selectedOption1 === "akt_dividende_etc" ||
        this.fieldsets.selectedOption1 === "kein_einkommen" ||
        this.fieldsets.selectedOption1 === "akt_sonstige"
      ) {
        returnObject[3] = true;
      }

      return returnObject;
    },
  },
  created() {
    if (this.proxyFieldset && this.disabled) {
      this.fieldsets = this.proxyFieldset;
    }
    this.loaded = true;
  },
  watch: {
    fieldsets: {
      handler(newF) {
        this.$emit("update", newF);
      },
      deep: true,
    },
  },
};
</script>
<style>
select {
  width: 100% !important;
}
</style>
