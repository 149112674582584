<template>
  <div>
    <div v-if="!hideNav" class="flex justify-center bg-white">
      <img src="@/assets/VBLogo.svg" alt="Logo Vakifbank" width="320" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      hideNav: false,
    };
  },
  async mounted() {
    await this.$router.isReady();
    if (this.$route.query.hideNav == "1") {
      this.hideNav = true;
    }
  },
};
</script>
