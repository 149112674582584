import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./index.css";
import { createI18n } from "vue-i18n";
import "v-calendar/style.css";
import VCalendar from "v-calendar";

const messages = {
  de: {
    monthlyAmount: "Monatlich in EUR",
    employer: "Arbeitgeber ",
    createAccount: "Konto eröffnen",
    readFirst:
      "Bevor Sie fortfahren, ersuchen wir Sie folgende Erklärungen abzugeben:",
    youAccept: "Sie nehmen zur Kenntnis, dass unsere ",
    youAccept_1: "unter dem Link abrufbar sind.",
    youAccept2: "Sie nehmen unsere",
    youAccept2_1: "zur Kenntnis.",
    dataTerms: "Datenschutzrichtlinien",
    recall: "Widerrufsbelehrung",
    recall_at: "Rücktrittsbelehrung",
    gotEverything:
      "Hiermit erklären Sie, dass die Eröffnung des Termingeldkontos und des erforderlichen Abwicklungskontos auf eigene Rechnung erfolgt und Sie der/die wirtschaftlich Berechtigte sind. Der/Die Kontoinhaber sind einzelzeichnungsberechtigt.",
    newMessage:
      "Ich nehme hiermit zur Kenntnis, dass meine personen- sowie kontobezogenen Daten entweder aufgrund der gesetzlichen Bestimmungen des Gemeinsamen Meldestandard- Gesetzes(GMSG) im Falle einer steuerlichen Ansässigkeit in einem teilnehmenden Staat, an das österreichische Bundesministerium für Finanzen gemeldet und von diesem an die Steuerbehörden des(r) entsprechenden Ansässigkeitsstaates(en) weitergeleitet werden, oder aufgrund der gesetzlichen Bestimmungen gem.FATCA(IGA - Österreichisches FATCA - Abkommen mit den USA) im Falle einer steuerlichen Ansässigkeit in den USA, an die US.- Steuerbehörde IRS (Internal Revenue Service) gemeldet sowie Stand: 2020 / 10 verarbeitet werden. Folgende Daten werden gemeldet: Name, Geburtsdatum/ -ort / -land, Hauptwohnsitzadresse, Land/Länder der steuerlichen Ansässigkeit(en), Steueridentifikationsnummer(n), Konto - /Depotnummer(n), Kontosaldo/en, Bruttoerträge(Zinsen, und im Fall von Wertpapierdepots auch Dividenden undandere Erträge) sowie Bruttoerlöse.Ich erkläre an Eides statt und versichere,dass ich alle Angaben in diesem Formular geprüft und nach bestem Wissen undGewissen wahrheitsgemäß, vollständig und korrekt gemacht habe.Ich verpflichtemich, ewaige Änderungen dieser Angaben dem konto - /depotführenden Finanzinstitut innerhalb von 30 Tagen mitzuteilen.",
    iAccept: "ICH BESTÄTIGE DIE OBIGEN ANGABEN",
    step4stepExplanaition: "Schritt für Schritt Anleitung",
    stepForStepText:
      "Wir haben den Prozess der Konto Erstellung so einfach wie möglich gemacht. In wenigen Minuten können Sie bei uns kostenfrei ein Konto erstellen.",
    step1: "Schritt 1: Formular",
    step1Explained:
      "Das Formular ist sehr simpel und kann in ca. 5 Minuten online ausgefüllt werden.",
    step2: "Schritt 2: Identifikation",
    step2Explained:
      "Nachdem Sie das Formular ausgefüllt haben, erhalten Sie eine E-Mail mit einem Link, mit dem Sie sich online per Videocall identifizieren können.",
    step3: "Schritt 3: Fertig",
    step3Explained:
      "Nach erfolgreicher Online-Video-Identifikation wird die Bank Ihren Antrag bearbeiten und Sie schriftlich über die erfolgreiche Kontoeröffnung informieren.",
    budgetAndTime: "Budget und Zeitrahmen",
    amount: "Betrag",
    continue: "Weiter",
    missingCountry: "Fehlende Land Information",
    personalInfo: "Persönliche Angaben",
    createSharedAccount:
      "Ich möchte dieses Konto als Gemeinschaftskonto erstellen.",
    infoSecondPerson:
      "Persönliche Angaben für zweite Person (Gemeinschaftskonto)",
    back: "Zurück",
    salutation: "Anrede",
    zugewinngemeinschaft: "Zugewinngemeinschaft",
    gütergemeinschaft: "Gütergemeinschaft",
    güterstand: "Güterstand",
    gütertrennung: "Gütertrennung",
    chooseSalutation: "Bitte wählen Sie eine Anrede",
    mr: "Herr",
    mrs: "Frau",
    title: "Titel",
    plsChoose: "Bitte auswählen",
    firstName: "Vorname",
    lastName: "Nachname",
    zipCode: "Postleitzahl",
    city: "Ort",
    street: "Straße",
    streetNo: "Hausnummer",
    didntChooseCountry1: "Sie haben nicht",
    didntChooseCountry2: "als Land gewählt, bitte",
    contactUs: "kontaktieren Sie uns",
    toCreateAccount:
      "um Ihren Account zu erstellen. Sie können über dieses Online Formular nicht fortfahren.",
    checkIfApplicable: "Bitte falls zutreffend ankreuzen",
    usCitizen: "Sind Sie U.S. Staatsbürger?",
    IRS1: "Sie müssen zusätzlich das",
    IRS2: "IRS-Formular W-9",
    IRS3: "und einen Consent Report ausfüllen.",
    forUsCitizens1: "Für Bürger der USA und Inhaber von Green Cards",
    forUsCitizens2:
      "Ich besitze eine Arbeits- / Aufenthaltserlaubnis in den USA (Green Card) oder bin US-Bürger. Im Sinne der Bestimmungen des Foreign Account Tax Compliance Act (FATCA) entbinde ich die VakifBank International AG von den Verschwiegenheitsbestimmungen des österreichischen Bankgeheimnisses. Gemaess dem Foreign Account Tax Compliance Act (FATCA) hat die VakifBank mit IRS, Internal Revenue Service (US Steuerbehoerde) ein Abkommen abgeschlossen. Die VakifBank International AG darf Personen-, Konto- und Transaktionsdaten im Sinne von FATCA an die zustaendigen US-Behoerden weitergeben. Ein Widerruf dieser Ermaechtigung führt zur Kündigung der Kontoverbindung durch die VakifBank International AG.",
    notInAustria:
      "Hiermit bestätige ich, dass ich keinen Wohnsitz bzw. außergewöhnlichen Aufenthalt iSd § 26 BAO in Österreich habe",
    twoTimesInAustria:
      "Hiermit bestätige ich, dass ich einen Zweitwohnsitz iSd § 1 Zweitsitzverordnung in Österreich habe - bitte füllen Sie zusätzlich das Zweitwohnsitzerklärungsformular aus",
    areYouPEP:
      "Sind Sie eine eine politisch exponierte Person - PEP bzw. eine nahestehende Familienangehörige Person PEP?",
    functionAsPEP: "Genaue Beschreibung der Funktion als PEP",
    taxedInAnotherCountry: "Sind Sie in einem anderen Land steuerpflichtig?",
    taxNr: "Steuernummer",
    justTheTruth:
      "Der Kontoinhaber versichert, dass alle oben gemachten Angaben über die steuerliche(n) Ansässigkeit(en) wahrheitsgemäß und vollständig sind und verpflichtet sich hiermit, etwaige Änderungen dieser Angaben der Bank gegenüber innerhalb von 30 Tagen anzuzeigen",
    kst1: "Ich habe die",
    kst2: "Hinweise über das Kirchensteuerverfahren",
    readAndAccept: "gelesen und akzeptiere",
    bday: "Geburtsdatum",
    bplace: "Geburtsort",
    nationality: "Nationalität",
    maritalStatus: "Familienstand",
    single: "Ledig",
    partnership: "Partnerschaft",
    married: "Verheiratet",
    divorced: "Geschieden",
    widowed: "Verwitwet",
    occupation: "Beschäftigung",
    employed: "Angestellt",
    worker: "Arbeiter",
    civilServant: "Beamter",
    unemployed: "Beschäftigungslos",
    housewife: "Hausfrau / Hausmann",
    retired: "Pensionist",
    student: "Schüler / Student",
    selfEmployed: "Selbstständig",
    contact: "Kontakt",
    phone: "Telefonnummer",
    phoneInternational: "Telefonnummer (international)",
    legitimation: "Legitimation",
    legitimationType: "Lichtbildausweis",
    passport: "Reisepass",
    idCard: "Personalausweis",
    agency: "Ausstellende Behörde",
    dateOfIssue: "Ausstellungsdatum",
    invPeriod: "Anlagedauer",
    forwhichcountry: "In welchem Land befinden Sie sich?",
    aut: "Österreich",
    ger: "Deutschland",
    hungary: "Ungarn",
    forwhichaccounttype:
      "Für welchen Kontotyp wollen Sie den Ertragsrechner nutzen?",
    timedeposit: "Termingeld",
    savingsbook: "Sparbuch",
    calcExplained:
      "Mit dem Ertragsrechner berechnen Sie schnell und unverbindlich, was Sie sich an Zinsen erwarten können. Verwenden Sie den Schieberegler zur Einstellung der Laufzeit und geben Sie die zu berechnende Geldmenge unverbindlich an.",
    investmentMode: "Anlageart",
    didntWork: "Leider nicht geklappt",
    didntWorkExplained:
      "Leider hat das nicht funktioniert... Das kann viele Gründe haben, versuchen Sie es am besten später erneut. Danke für Ihr Verständnis.",
    interest: "Verzinsung",
    totalInterest: "Gesamtbetrag in Euro nach",
    months: "Monaten",
    keepKEST: "Gesetzliche KESt wird einbehalten",
    country: "Land",
    jointAcc: "Gemeinschaftskonto",
    months2: "Monate",
    month: "Monat",
    year: "Jahr",
    years: "Jahre",
    // .....
    moreOptions: "Weitere Optionen",
    description: "Beschreibung",
    number: "Nummer",
    step: "Schritt",
    recipientName: "Empfängername",
    referenceAccount: "Referenzkonto",
    dataConfirmation: "Bestätigung Ihrer Daten",
    chooseOptionNicely:
      "Bitte wählen Sie zumindest eine der folgenden Optionen",
    backgroundOfRelation:
      "Hintergrund der Geschäftsbeziehungen bzw. Inland-Bezug (Mehrfachauswahl)",
    workLoactionThingy:
      "Dienstort im Inland / Selbstständige wirtschaftliche Tätigkeit im Inland",
    locationChange: "Wohnsitz(-änderung)",
    buyRealEstate: "Immobilienerwerb",
    mediationBy: "Vermittlung/Empfehlung durch bestehenden Kunden:-/-",
    austriaAsSafePlace: "Österreich als sicherer Finanzplatz",
    longtermInvestmentsInland: "Längerfristige Veranlagung im Inland",
    others: "Sonstiges",
    explanation: "Erklärung",
    originOfFunds:
      "Herkunft der aktuellen/künftigen Vermögenswerte (Mehrfachauswahl)",
    concurringIncome:
      " Ersparnisse aus laufendem Einkommen aus unselbständiger Tätigkeit / Unterhalt (z-B.: Lohn/Gehalt)",
    independentConcurringIncome:
      " Ersparnisse aus laufendem Einkommen aus freiberuflicher / selbständiger Tätigkeit",
    threeOptions: " Ersparnisse aus Erträgen / Dividenden / Mieteinnahmen",
    fourOptions: " Ersparnisse aus Verkäufen von virtuellen Währungen",
    twoOptions: "Erbschaft / Schenkung",
    sellingOfAssets:
      "Verkauf von Vermögenswerten (z.B.: Immobilien, Wertpapiere, Unternehmensbeteiligungen)",
    endOfInsurance:
      "Ablauf von Versicherungen und Verträgen (z.B.: Lebensversicherung, Bausparverträge)",
    plannedUseOfAssets:
      "Geplanter Verwendungszweck des Vermögens (Mehrfachauswahl)",
    livelyhood: "Lebensunterhalt",
    supportOfFamily: "Unterstützung im Familienkreis (inkl. Schenkung)",
    investInFinance: "Veranlagung in Finanzprodukte",
    realEstateInvestment: "Immobilienerwerb",
    investinBusiness: "Veranlagung in Unternehmensbeteiligungen",
    helpGstand:
      "Beim Güterstand geht es um die Frage, in welcher Form Sie das eigene und das gemeinsame Vermögen zukünftig nutzen und teilen wollen. Das kann schon während der Ehe eine Rolle spielen, gewinnt aber vor allem dann an Bedeutung, wenn die Partnerschaft freiwillig oder unfreiwillig beendet wird.",
    further: "Weiteres",
    /* contact options */
    howToBeContacted: "Wie wollen Sie kontaktiert werden?",
    call_contact: "anrufen",
    contactByEmail: "per elektronischer Post kontaktieren",
    callAndContactByEmail: "anrufen und per elektronischer Post kontaktieren",
    iAgree: "Ich/wir willigen ein.",
    dis_iAgree: "ich/wir willigen nicht ein",
    /* end */
    iPlanMoreInvestments:
      "Ich plane noch weitere Veranlagungen bei der VakifBank International AG",
    calculator: "Ertragsrechner",
    runTime: "Laufzeit (in Monaten)",
    zinssatzPA: "Zinssatz p.a.",
    couldNotFind: "Konnte keine Zinsinformationen finden...",
    /* new fieldset 4 */
    akt_originOfFunds: "Aktuelles bzw. künftiges Einkommen (Mehrfachauswahl)",
    akt_concurringIncome:
      "Laufendes Einkommen aus unselbständiger Tätigkeit / Unterhalt (z-B.: Lohn/Gehalt)",
    akt_independentConcurringIncome:
      " Laufendes Einkommen aus freiberuflicher / selbständiger Tätigkeit",
    akt_threeOptions: " Laufende Erträge / Dividenden / Mieteinnahmen",
    akt_other: " Sonstiges laufendes Einkommen z.B. (Pension)",
    kein_einkommen: " Kein laufendes Einkommen (z.B., Erbschaft / Schenkung)",
    selectedOption: "",
    selectedOption1: "",
  },
  en: {
    monthlyAmount: "Monthly in EUR",
    employer: "Employer",

    createAccount: "Open Account",
    readFirst: "Before proceeding, we ask you for the following declarations:",
    youAccept: "You acknowledge that our ",
    youAccept_1: "is available under the link.",
    youAccept2: "You acknowledge our",
    youAccept2_1: ".",
    dataTerms: "Data Privacy Policy",
    recall: "Cancellation Policy",
    recall_at: "Cancellation Policy",
    gotEverything:
      "By this, you declare that the opening of the fixed-term deposit account and the necessary settlement account is done at your own expense and you are the beneficial owner. The account holder(s) have sole signing authority.",
    newMessage:
      "I hereby acknowledge that my personal and account-related data will either be reported to the Austrian Federal Ministry of Finance due to the legal provisions of the Common Reporting Standard Law (CRS) in the event of tax residence in a participating state, and forwarded by the Austrian Federal Ministry of Finance to the tax authorities of the respective state(s) of residence, or reported to the U.S. tax authority IRS (Internal Revenue Service) and processed, as of 2020/10, due to the legal provisions of FATCA (IGA - Austrian FATCA Agreement with the USA) in the event of tax residence in the USA. The following data will be reported: Name, Date of Birth/Place/Country, Main Residence Address, Country/Countries of tax residence(s), Tax Identification Number(s), Account/Depot Number(s), Account balance(s), Gross proceeds (interests, and in the case of securities accounts also dividends and other earnings), and Gross income. I swear and affirm that I have verified all the information in this form and to the best of my knowledge and belief, it is true, complete, and correct. I commit to informing the account/depot-keeping financial institution of any changes to this information within 30 days.",
    iAccept: "I CONFIRM THE INFORMATION ABOVE",
    step4stepExplanaition: "Step-by-Step Guide",
    stepForStepText:
      "We have made the account creation process as easy as possible. You can create an account with us for free in just a few minutes.",
    step1: "Step 1: Form",
    step1Explained:
      "The form is very simple and can be completed online in about 5 minutes.",
    step2: "Step 2: Identification",
    step2Explained:
      "After filling out the form, you will receive an email with a link to identify yourself online via video call.",
    step3: "Step 3: Done",
    step3Explained:
      "After successful online video identification, the bank will process your application and inform you in writing about the successful account opening.",
    budgetAndTime: "Budget and Timeframe",
    amount: "Amount",
    continue: "Continue",
    missingCountry: "Missing Country Information",
    personalInfo: "Personal Information",
    createSharedAccount: "I want to create this account as a joint account.",
    infoSecondPerson:
      "Personal Information for the Second Person (Joint Account)",
    back: "Back",
    salutation: "Salutation",
    zugewinngemeinschaft: "Joint ownership",
    gütergemeinschaft: "Joint property",
    güterstand: "Matrimonial property regime",
    gütertrennung: "Separation of property",
    chooseSalutation: "Please choose a salutation",
    helpGstand:
      "The matrimonial property regime is about the question of how you want to use and share your own and joint assets in the future. This can already play a role during the marriage, but becomes particularly important when the partnership ends voluntarily or involuntarily.",
    mr: "Mr.",
    mrs: "Mrs.",
    title: "Title",
    plsChoose: "Please choose",
    firstName: "First Name",
    lastName: "Last Name",
    zipCode: "ZIP Code",
    city: "City",
    street: "Street",
    streetNo: "Street Number",
    didntChooseCountry1: "You did not choose",
    didntChooseCountry2: "as the country, please",
    contactUs: "contact us",
    toCreateAccount:
      "to create your account. You cannot proceed through this online form.",
    checkIfApplicable: "Please check if applicable",
    usCitizen: "Are you a U.S. citizen?",
    IRS1: "You must also complete the",
    IRS2: "IRS Form W-9",
    IRS3: "and a Consent Report.",
    forUsCitizens1: "For U.S. Citizens and Green Card Holders",
    forUsCitizens2:
      "I have a work/residence permit in the USA (Green Card) or I am a U.S. citizen. Within the meaning of the provisions of the Foreign Account Tax Compliance Act (FATCA), I release VakifBank International AG from the confidentiality provisions of Austrian bank secrecy. According to the Foreign Account Tax Compliance Act (FATCA), VakifBank has entered into an agreement with the IRS, Internal Revenue Service (U.S. Tax Authority). VakifBank International AG may disclose personal, account, and transaction data under FATCA to the relevant U.S. authorities. Revoking this authorization leads to the termination of the account relationship by VakifBank International AG.",
    notInAustria:
      "I hereby confirm that I do not have a residence or extraordinary residence according to § 26 BAO in Austria",
    twoTimesInAustria:
      "I hereby confirm that I have a second residence according to § 1 Second Residence Regulation in Austria - please also complete the Second Residence Declaration form",
    areYouPEP:
      "Are you a politically exposed person (PEP) or a closely related family member of a PEP?",
    functionAsPEP: "Precise description of the function as a PEP",
    taxedInAnotherCountry: "Are you liable for tax in another country?",
    taxNr: "Tax Number",
    justTheTruth:
      "The account holder affirms that all statements made above regarding tax residence(s) are true and complete, and hereby commits to notify the bank of any changes to this information within 30 days",
    kst1: "I have read the",
    kst2: "Notes on Church Tax Procedure",
    readAndAccept: "read and accept",
    bday: "Date of Birth",
    bplace: "Place of Birth",
    nationality: "Nationality",
    maritalStatus: "Marital Status",
    single: "Single",
    partnership: "Partnership",
    married: "Married",
    divorced: "Divorced",
    widowed: "Widowed",
    occupation: "Occupation",
    employed: "Employed",
    worker: "Worker",
    civilServant: "Civil Servant",
    unemployed: "Unemployed",
    housewife: "Homemaker",
    retired: "Retired",
    student: "Student",
    selfEmployed: "Self-Employed",
    contact: "Contact",
    phone: "Phone Number",
    phoneInternational: "Phone Number (International)",
    legitimation: "Legitimation",
    legitimationType: "Photo ID",
    passport: "Passport",
    idCard: "ID Card",
    agency: "Issuing Authority",
    dateOfIssue: "Date of Issue",
    invPeriod: "Investment Period",
    calculator: "Interest Calculator",
    forwhichcountry: "Choose your country",
    aut: "Austria",
    ger: "Germany",
    hungary: "Hungary",
    forwhichaccounttype:
      "For which account type do you want to use the interest calculator?",
    timedeposit: "Fixed-Term Deposit",
    savingsbook: "Savings Book",
    calcExplained:
      "With the interest calculator, you can quickly and without obligation calculate the interest you can expect. Use the slider to set the duration and provide the amount to be calculated without obligation.",
    investmentMode: "Investment Type",
    didntWork: "Unfortunately, it didn't work",
    didntWorkExplained:
      "Unfortunately, that didn't work... There could be many reasons for this. Please try again later. Thank you for your understanding.",
    interest: "Interest",
    totalInterest: "Total Yield in Euro after",
    months: "months",
    keepKEST: "Statutory capital gains tax (KESt) will be withheld",
    country: "Country",
    jointAcc: "Joint Account",
    months2: "Months",
    month: "Month",
    year: "Year",
    years: "Years",
    moreOptions: "More Options",
    description: "Description",
    number: "Number",
    step: "Step",
    recipientName: "Recipient Name",
    referenceAccount: "Reference Account",
    dataConfirmation: "Confirmation of Your Data",
    chooseOptionNicely: "Please choose at least one of the following options",
    backgroundOfRelation:
      "Background of Business Relationship or Domestic Reference (Multiple Selection)",
    workLoactionThingy:
      "Work Location in Domestic / Independent Economic Activity in Domestic",
    locationChange: "Residence Change",
    buyRealEstate: "Real Estate Purchase",
    mediationBy: "Recommendation by Existing Customer: - / -",
    austriaAsSafePlace: "Austria as a Safe Financial Place",
    longtermInvestmentsInland: "Long-Term Investment in Domestic",
    others: "Others",
    explanation: "Explanation",
    originOfFunds: "Origin of Current/Future Assets (Multiple Selection)",
    concurringIncome:
      "Ongoing Income from Non-Independent Activity / Maintenance (e.g.: Salary / Wage)",
    independentConcurringIncome:
      "Ongoing Income from Freelance / Independent Activity",

    threeOptions: "Earnings / Dividends / Rental Income",
    fourOptions: "Savings from virtual currency sales",
    twoOptions: "Inheritance / Gift",
    sellingOfAssets:
      "Sale of Assets (e.g.: Real Estate, Securities, Company Holdings)",
    endOfInsurance:
      "Expiration of Insurances and Contracts (e.g.: Life Insurance, Building Savings Contracts)",
    plannedUseOfAssets: "Planned Use of Assets (Multiple Selection)",
    livelyhood: "Livelihood",
    supportOfFamily: "Support in Family Circle (Including Gift)",
    investInFinance: "Investment in Financial Products",
    realEstateInvestment: "Real Estate Purchase",
    investinBusiness: "Investment in Company Holdings",
    further: "Further",
    howToBeContacted: "How would you like to be contacted?",
    /* contact field */
    call_contact: "call",
    contactByEmail: "contact by email",
    callAndContactByEmail: "call and contact by email",
    iAgree: "I/we agree.",
    dis_iAgree: "I/we do not agree",
    /* end */
    iPlanMoreInvestments:
      "I plan to make further investments with VakifBank International AG",
    runTime: "Investment period (in months)",
    zinssatzPA: "Interest rate p.a.",
    couldNotFind: "Could not find interest rate information...",
    /* new field set 4 */
    akt_originOfFunds: "Current or future income (multiple selection)",
    akt_concurringIncome:
      "Income from employment/maintenance (e.g.: wages/salaries)",
    akt_independentConcurringIncome:
      "Income from freelance / self-employed work",
    akt_threeOptions: "Income from returns / dividends / rental income",
    akt_other: "Other ongoing income e.g., (Pension)",
    kein_einkommen: "No ongoing income (e.g., Inheritance / Gift)",
    selectedOption: "",
    selectedOption1: "",
  },
};

let locale = window.location.pathname.replace(/^\/([^/]+).*/i, "$1");

// check for valid locale
if (!["de", "en"].includes(locale.trim())) {
  locale = "de";
}

const i18n = createI18n({
  locale,
  messages,
  // something vue-i18n options here ...
});

createApp(App).use(VCalendar).use(router).use(i18n).use(store).mount("#app");
