<template>
    <slot />
</template>
  
<script>
export default {
    data() {
        return {
            weMayBeAtTheEnd: false,
        };
    },
    mounted() {
        window.addEventListener("scroll", () => {
            if (!this.weMayBeAtTheEnd) {
                let body = document.body,
                    html = document.documentElement;

                let height = Math.max(
                    body.scrollHeight,
                    body.offsetHeight,
                    html.clientHeight,
                    html.scrollHeight,
                    html.offsetHeight
                );

                let scrollY = Math.ceil(window.scrollY);

                const unseenPxlsY = Math.ceil(height - scrollY - window.innerHeight);

                if (unseenPxlsY < 400) {
                    this.$emit("at-bottom");
                }
            }
        });
    },
};
</script>
  