<template>
  <div>
    <div class="flex justify-between mb-4">
      <div class="mr-1 flex-1">
        <label class="block text-sm font-bold mb-2" :for="'sex' + id"
          >{{ $t("salutation") }}:</label
        >
        <select
          :id="'sex' + id"
          class="select w-full shadow border border-gray-200"
          required
          aria-required="true"
          :disabled="disabled"
          v-model="person.sex"
        >
          <option disabled selected>{{ $t("chooseSalutation") }}</option>
          <option value="mann">{{ $t("mr") }}</option>
          <option value="frau">{{ $t("mrs") }}</option>
        </select>
      </div>
      <div class="ml-1 flex-1">
        <label class="block text-sm font-bold mb-2" :for="'title' + id"
          >{{ $t("title") }}:</label
        >
        <select
          :id="'title' + id"
          class="select w-full shadow border border-gray-200"
          v-model="person.title"
          :disabled="disabled"
        >
          <option disabled selected>{{ $t("plsChoose") }}</option>
          <option value="mag">Mag.</option>
          <option value="dr">Dr.</option>
          <option value="ing">Ing.</option>
          <option value="di">DI.</option>
          <option value="ingmag">Ing. Mag.</option>
          <option value="dir">Dir.</option>
          <option value="univprof">Univ. Prof.</option>
          <option value="prof">Prof.</option>
          <option value="dipling">Dipl.- Ing. (HTL)</option>
          <option value="diplpad">Dipl.- Päd.</option>
          <option value="mmagdr">MMag. Dr.</option>
        </select>
      </div>
    </div>
    <div class="mb-4">
      <label class="block text-sm font-bold mb-2" :for="'firstName' + id"
        >{{ $t("firstName") }}:</label
      >
      <input
        class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        :id="'firstName' + id"
        v-model="person.firstName"
        type="text"
        :disabled="disabled"
        required
        :placeholder="$t('firstName')"
      />
    </div>
    <div class="mb-4">
      <label class="block text-sm font-bold mb-2" :for="'lastName' + id"
        >{{ $t("lastName") }}:</label
      >
      <input
        class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        :id="'lastName' + id"
        v-model="person.lastName"
        required
        :disabled="disabled"
        type="text"
        aria-required="true"
        :placeholder="$t('lastName')"
      />
    </div>
    <div class="flex flex-col sm:flex-row mb-4">
      <div class="flex-1 mb-4 sm:mb-0 sm:mr-1">
        <label class="block text-sm font-bold mb-2" :for="'plz' + id"
          >{{ $t("zipCode") }}:</label
        >
        <input
          class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          :id="'plz' + id"
          v-model="person.plz"
          aria-required="true"
          type="text"
          required
          :disabled="disabled"
          :placeholder="$t('zipCode')"
        />
      </div>
      <div class="flex-1 mb-4 sm:mb-0 sm:ml-1">
        <label class="block text-sm font-bold mb-2" :for="'city' + id"
          >{{ $t("city") }}:</label
        >
        <input
          class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          :id="'city' + id"
          v-model="person.city"
          type="text"
          aria-required="true"
          :disabled="disabled"
          required
          :placeholder="$t('city')"
        />
      </div>
    </div>
    <div class="flex flex-col sm:flex-row mb-2">
      <div class="mb-4 sm:mb-0 sm:mr-1 flex-1">
        <label class="block text-sm font-bold mb-2" :for="'street' + id"
          >{{ $t("street") }}:</label
        >
        <input
          class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          :id="'street' + id"
          v-model="person.street"
          required
          :disabled="disabled"
          type="text"
          :placeholder="$t('street')"
        />
      </div>
      <div class="mb-4 sm:mb-0 sm:ml-1 flex-1">
        <label class="block text-sm font-bold mb-2" :for="'streetNo' + id"
          >{{ $t("streetNo") }}:</label
        >
        <input
          class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          :id="'streetNo' + id"
          v-model="person.streetNo"
          required
          aria-required="true"
          :disabled="disabled"
          type="text"
          :placeholder="$t('streetNo')"
        />
      </div>
    </div>
    <div class="mb-4">
      <CountryDropdown
        v-model="person.country"
        :disabled="disabled"
        :value-to-select="person.country"
        :label="$t('country')"
      />
      <p
        v-if="
          person.country != this.$route.query.country.toUpperCase() &&
          person.country != undefined &&
          person.country != ''
        "
        class="text-red-500 p-2"
      >
        {{
          $t("didntChooseCountry1") +
          ` ${countryMap[this.$route.query.country.toLowerCase()]} ` +
          $t("didntChooseCountry2")
        }}
        <a
          class="font-bold"
          href="https://vakifbank.at/de/kontakt"
          target="_blank"
          rel="noopener noreferrer"
          >{{ $t("contactUs") }}</a
        >
        {{ $t("toCreateAccount") }}
      </p>
    </div>
    <fieldset class="border my-2 px-2 rounded-lg shadow">
      <legend class="font-bold mx-1 p-2">{{ $t("checkIfApplicable") }}:</legend>
      <div>
        <div class="flex align-middle items-center">
          <input
            class="checkbox"
            :id="'check00' + id"
            :disabled="disabled"
            v-model="person.us_citizen"
            type="checkbox"
          />
          <label class="block text-sm p-4" :for="'check00' + id">{{
            $t("usCitizen")
          }}</label>
        </div>
        <p class="text-xs" v-if="person.us_citizen">
          🇺🇸 {{ $t("IRS1") }}
          <a
            href="https://www.irs.gov/forms-pubs/about-form-w-9"
            target="__blank"
            class="underline"
            >{{ $t("IRS2") }}</a
          >
          {{ $t("IRS3") }}
        </p>
      </div>
      <div class="flex align-middle items-center" v-if="person.us_citizen">
        <input
          class="checkbox"
          :id="'us_citizen_rule' + id"
          v-model="person.us_citizen_rule"
          type="checkbox"
          required
          :disabled="disabled"
          aria-required="true"
        />
        <label class="block text-sm p-4" :for="'us_citizen_rule' + id">
          🇺🇸 <strong>{{ $t("forUsCitizens1") }}:</strong
          >{{ $t("forUsCitizens2") }}</label
        >
      </div>
      <div v-if="this.$route.query.country == 'at'">
        <div class="flex align-middle items-center">
          <input
            class="checkbox"
            :id="'check01' + id"
            :disabled="disabled"
            v-model="person.extra_rule_austria"
            type="checkbox"
          />
          <label class="block text-sm p-4" :for="'check01' + id"
            >{{ $t("notInAustria") }}.</label
          >
        </div>
        <div class="flex align-middle items-center">
          <input
            class="checkbox"
            :id="'check02' + id"
            v-model="person.zweitwohnsitz"
            type="checkbox"
            :disabled="disabled"
          />
          <label class="block text-sm p-4" :for="'check02' + id"
            >{{ $t("twoTimesInAustria") }}.</label
          >
        </div>
      </div>
      <div class="flex align-middle items-center">
        <input
          class="checkbox"
          :id="'check044' + id"
          v-model="person.pep"
          type="checkbox"
          :disabled="disabled"
        />
        <label class="block text-sm p-4" :for="'check044' + id">{{
          $t("areYouPEP")
        }}</label>
      </div>
      <div v-if="person.pep">
        <label class="block text-sm font-bold mb-2 p-2" for="pep_text"
          >{{ $t("functionAsPEP") }}:</label
        >
        <input
          class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          id="pep_text"
          v-model="person.pep_text"
          type="text"
          required
          aria-required="true"
          :placeholder="$t('description')"
          :disabled="disabled"
        />
      </div>
      <div
        v-if="$route.query.country == 'de'"
        class="flex align-middle items-center"
      >
        <input
          class="checkbox"
          :id="'anderesSteuerLand' + id"
          v-model="person.steuerpflichtigAusland"
          type="checkbox"
          :disabled="disabled"
        />
        <label class="block text-sm p-4" :for="'anderesSteuerLand' + id">{{
          $t("taxedInAnotherCountry")
        }}</label>
      </div>
      <SteuerpflichtigeCountries
        v-if="person.steuerpflichtigAusland"
        v-model="person.steuerInfo"
        :disabled="disabled"
        :initial-info="person.steuerInfo"
      />
    </fieldset>
    <div class="flex flex-col mb-4" v-if="$route.query.country == 'de'">
      <div class="mb-4 sm:mb-0 sm:mr-1 flex-1">
        <label class="block text-sm font-bold mb-2" :for="'steuernummer' + id"
          >{{ $t("taxNr") }}:</label
        >
        <input
          class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          :id="'steuernummer' + id"
          v-model="person.steuernummer"
          required
          :disabled="disabled"
          aria-required="true"
          minlength="11"
          maxlength="11"
        />
      </div>
      <div class="flex align-middle items-center">
        <input
          class="checkbox"
          :id="'kontohaber_ok' + id"
          :disabled="disabled"
          v-model="person.crs_ok"
          type="checkbox"
          required
        />
        <label class="block text-sm p-4" :for="'kontohaber_ok' + id">{{
          $t("justTheTruth")
        }}</label>
      </div>
      <div class="flex align-middle items-center">
        <input
          class="checkbox"
          :id="'kirchensteuerverfahren_input' + id"
          :disabled="disabled"
          v-model="person.kirchensteuerverfahren_ok"
          type="checkbox"
          required
        />
        <label
          class="block text-sm p-4"
          :for="'kirchensteuerverfahren_input' + id"
          >{{ $t("kst1") }}
          <a href="/kirchensteuerverfahren" class="underline" target="_blank">{{
            $t("kst2")
          }}</a>
          {{ $t("readAndAccept") }}.</label
        >
      </div>
    </div>
    <div class="flex flex-col sm:flex-row mb-4">
      <div class="mb-4 sm:mb-0 sm:mr-1 flex-1">
        <label class="block text-sm font-bold mb-2" :for="'bday' + id"
          >{{ $t("bday") }}:</label
        >
        <input
          class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          :id="'bday' + id"
          v-model="person.birthday"
          required
          :disabled="disabled"
          aria-required="true"
          type="date"
        />
      </div>
      <div class="mb-4 sm:mb-0 sm:ml-1 flex-1">
        <label class="block text-sm font-bold mb-2" :for="'bdaycity' + id"
          >{{ $t("bplace") }}:</label
        >
        <input
          class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          :id="'bdaycity' + id"
          v-model="person.birthCity"
          required
          aria-required="true"
          type="text"
          :disabled="disabled"
          :placeholder="$t('bplace')"
        />
      </div>
    </div>
    <div class="mb-4">
      <CountryDropdown
        :label="$t('nationality')"
        v-model="person.nationality"
        :disabled="disabled"
        :value-to-select="person.nationality"
      />
    </div>
    <div class="mb-4">
      <label class="block text-sm font-bold mb-2" :for="'famstand' + id"
        >{{ $t("maritalStatus") }}:</label
      >
      <select
        :id="'famstand' + id"
        class="select w-full shadow border border-gray-200"
        required
        aria-required="true"
        v-model="person.familienStand"
        :disabled="disabled"
      >
        <option disabled selected>{{ $t("plsChoose") }}:</option>
        <option value="ledig">{{ $t("single") }}</option>
        <option value="partnerschaft">{{ $t("partnership") }}</option>
        <option value="verheiratet">{{ $t("married") }}</option>
        <option value="geschieden">{{ $t("divorced") }}</option>
        <option value="verwitwet">{{ $t("widowed") }}</option>
      </select>
    </div>
    <div class="mb-4">
      <label class="block text-sm font-bold mb-2" :for="'works' + id"
        >{{ $t("occupation") }}:</label
      >
      <select
        :id="'works' + id"
        class="select w-full shadow border border-gray-200"
        aria-required="true"
        :disabled="disabled"
        v-model="person.beschaeftigung"
      >
        <option disabled selected>{{ $t("plsChoose") }}:</option>
        <option value="Angestellter">{{ $t("employed") }}</option>
        <option value="Arbeiter">{{ $t("worker") }}</option>
        <option value="Beamter">{{ $t("civilServant") }}</option>
        <option value="Beschaeftigungslos">{{ $t("unemployed") }}</option>
        <option value="Hausfrau / Hausmann">{{ $t("housewife") }}</option>
        <option value="Pensionist">{{ $t("retired") }}</option>
        <option value="Schüler / Student">{{ $t("student") }}</option>
        <option value="Selbstständig">{{ $t("selfEmployed") }}</option>
      </select>
    </div>
    <h2 class="font-bold mb-4">{{ $t("contact") }}</h2>
    <div class="mb-4">
      <label class="block text-sm font-bold mb-2" :for="'telNr' + id"
        >{{ $t("phone") }}:</label
      >
      <input
        class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        :id="'telNr' + id"
        v-model="person.telephone"
        required
        :disabled="disabled"
        type="text"
        aria-required="true"
        :placeholder="$t('phoneInternational')"
      />
    </div>
    <div class="mb-4">
      <label class="block text-sm font-bold mb-2" :for="'email' + id"
        >E-Mail:</label
      >
      <input
        class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        :id="'email' + id"
        v-model="person.email"
        required
        type="email"
        aria-required="true"
        :disabled="disabled"
        placeholder="E-mail "
      />
    </div>
    <h2 class="font-bold mb-4">{{ $t("legitimation") }}</h2>
    <div class="mb-4">
      <label class="block text-sm font-bold mb-2" :for="'ausweisTyp' + id"
        >{{ $t("legitimationType") }}:</label
      >
      <select
        :id="'ausweisTyp' + id"
        class="select w-full shadow border border-gray-200"
        required
        aria-required="true"
        v-model="person.ausweisTyp"
        :disabled="disabled"
      >
        <option disabled selected>{{ $t("plsChoose") }}:</option>
        <option value="pass">{{ $t("passport") }}</option>
        <option value="perso">{{ $t("idCard") }}</option>
      </select>
    </div>
    <div class="mb-4">
      <label class="block text-sm font-bold mb-2" :for="'ausweisNr' + id"
        >{{ $t("number") }}:</label
      >
      <input
        class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        :id="'ausweisNr' + id"
        v-model="person.ausweisNummer"
        required
        type="text"
        aria-required="true"
        :disabled="disabled"
        placeholder=""
      />
    </div>
    <div class="mb-4">
      <label class="block text-sm font-bold mb-2" :for="'ausweisAgency' + id"
        >{{ $t("agency") }}:</label
      >
      <input
        class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        :id="'ausweisAgency' + id"
        v-model="person.ausweisAgency"
        required
        :disabled="disabled"
        type="text"
        aria-required="true"
        :placeholder="$t('agency')"
      />
    </div>
    <div class="mb-4">
      <label class="block text-sm font-bold mb-2" :for="'ausweisDate' + id"
        >{{ $t("dateOfIssue") }}:</label
      >
      <input
        class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        :id="'ausweisDate' + id"
        v-model="person.ausweisDatum"
        required
        :disabled="disabled"
        type="date"
        aria-required="true"
      />
    </div>
    <div class="mb-4" v-if="this.$route.query.country == 'de' && shared">
      <label class="block text-sm font-bold mb-2" :for="'guut' + id"
        >{{ $t("güterstand") }}{{ shared ? ":" : " (optional):" }}</label
      >
      <select
        :id="'guut' + id"
        class="select w-full shadow border border-gray-200"
        aria-describedby="helpGstand"
        :required="shared ? true : false"
        :aria-required="shared ? true : false"
        :disabled="disabled"
        v-model="person.gstand"
      >
        <option disabled selected>{{ $t("plsChoose") }}:</option>
        <option value="zugewinn">{{ $t("zugewinngemeinschaft") }}</option>
        <option value="gemeinschaft">{{ $t("gütergemeinschaft") }}</option>
        <option value="trennung">{{ $t("gütertrennung") }}</option>
      </select>
      <p v-if="!disabled" id="helpGstand" class="text-gray-700 text-xs p-1">
        {{ $t("helpGstand") }}
      </p>
    </div>
  </div>
</template>

<script>
import CountryDropdown from "@/components/CountryDropdown.vue";
import { toRaw } from "vue";
import SteuerpflichtigeCountries from "./SteuerpflichtigeCountries.vue";

export default {
  components: {
    CountryDropdown,
    SteuerpflichtigeCountries,
  },
  props: {
    id: {
      type: Number,
      default: 0,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    proxyPerson: {
      type: Object,
      default: () => {},
    },
    shared: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      person: {
        extra_rule_austria: false,
        austria_exception: false,
        us_citizen: false,
        us_citizen_rule: false,
        zweitwohnsitz: false,
        pep: false,
        pep_text: "",
        sex: "",
        title: "",
        firstName: "",
        lastName: "",
        plz: "",
        city: "",
        street: "",
        country: null,
        streetNo: "",
        birthday: "",
        birthCity: "",
        nationality: "",
        familienStand: "",
        beschaeftigung: "",
        telephone: "",
        email: "",
        ausweisTyp: "",
        ausweisNummer: "",
        ausweisAgency: "",
        ausweisDatum: "",
        anderesSteuerLand: "",
        steuerInfo: [],
        steuerpflichtigAusland: false,
        steuernummer: "Meine Steuernummer (TEST)",
        crs_ok: false,
        kirchensteuerverfahren_ok: false,
      },
      countryMap: {
        de: this.$t("ger"),
        at: this.$t("aut"),
      },
    };
  },
  created() {
    if (this.proxyPerson) {
      console.log(toRaw(this.proxyPerson));
      this.person = toRaw(this.proxyPerson);
    }
  },
  watch: {
    person: {
      handler(newP) {
        if (!this.proxyPerson) {
          console.log("Person changed");
          if (newP.firstName == "test") {
            this.fillDemoData();
          }

          if (String(newP.country).toLowerCase() != this.$route.query.country) {
            if (newP.country == "DE" || newP.country == "AT") {
              // to translate
              if (
                window.confirm(
                  `Mit einem Wohnsitz in ${
                    this.countryMap[newP.country.toLowerCase()]
                  } können Sie sich ein Konto bei Vakifbank ${
                    this.countryMap[newP.country.toLowerCase()]
                  } erstellen. Wollen Sie, dass wir Sie weiterleiten?`
                )
              ) {
                this.$router.push(
                  "/konto-erstellen?country=" + newP.country.toLowerCase()
                );
              } else {
                this.person.country = "";
              }
            }
          }
          this.$emit("update", newP);
        }
      },
      deep: true,
    },
  },
  methods: {
    fillDemoData() {
      if (this.id == "1") {
        this.person.firstName = "Test";
        this.person.lastName = "Test";
        /*  this.person.sex = "mann"; */
        this.person.plz = "1010";
        this.person.city = "Wien";
        this.person.street = "Traumstraße";
        this.person.streetNo = "1";
        this.person.birthCity = "Wien";
        this.person.nationality = this.$route.query.country.toUpperCase();
        this.person.familienStand = "ledig";
        this.person.beschaeftigung = "arbeiter";
        this.person.telephone = "069912343233";
        this.person.email = "test@mail.com";
        this.person.ausweisTyp = "pass";
        this.person.country = this.$route.query.country.toUpperCase();
        this.person.ausweisNummer = "Test Nummer";
        this.person.ausweisAgency = "Test Behörde";
        this.person.birthday = "1990-01-01";
        this.person.ausweisDatum = "2017-01-01";
      } else if (this.id == "2") {
        this.person.firstName = "Maxi";
        this.person.lastName = "Mustermanna";
        /*   this.person.sex = "frau"; */
        this.person.plz = "1010";
        this.person.city = "Wien";
        this.person.street = "Wolkenstraße";
        this.person.streetNo = "1";
        this.person.birthCity = "Wien";
        this.person.nationality = this.$route.query.country.toUpperCase();
        this.person.familienStand = "ledig";
        this.person.beschaeftigung = "arbeiter";
        this.person.telephone = "069914227743";
        this.person.email = "super@mario.com";
        this.person.ausweisTyp = "pass";
        this.person.country = this.$route.query.country.toUpperCase();
        this.person.ausweisNummer = "Ausweisnummer1234";
        this.person.ausweisAgency = "Behörde aller Behörden";
        this.person.birthday = "1990-01-01";
        this.person.ausweisDatum = "2017-01-01";
      }
      if (this.$route.query.country.toUpperCase() == "DE") {
        this.person.steuernummer = "Meine Steuernummer (TEST)";
        this.person.crs_ok = true;
        // this.person.gstand = "zugewinn";
      }
    },
  },
};
</script>
